import {RolePlayScenario, ScenarioRole} from "../models/RolePlayScenario";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useState} from "react";
import Masonry from '@mui/lab/Masonry';
import ImageListItem from "@mui/material/ImageListItem";
import {API_SERVER} from "../api/ApiEndpoints";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ScenarioInfoPopover from "./ScenarioInfoPopover";
import Box from "@mui/material/Box";

function rolePopoverRenderer(item: ScenarioRole) {
  return <>
    <Typography
      variant='h2'
      // sx={{ p: 2 }}
    >{item.gender}, age {item.age}</Typography>
    <Typography
      variant='subtitle1'
      // sx={{ p: 2 }}
    >Description: {item.description}</Typography>
  </>;
}

export function RolesList(props) {
  const {
    // scenario,
    scenario, setSelectedRole,
    stepMayChange, setStepMayChange
  } = props
  // const {
  //   scenarios
  // } = useAppStore()

  const [selectedImage, setSelectedImage] = useState("");

  const handleClick = (item: ScenarioRole) => {
    // console.log(item.imgFilename);
    // console.log('Image clicked');
    setSelectedImage(item.imgFilename);

    setStepMayChange(true);

    if (setSelectedRole) {
      setSelectedRole(item)
    }
  };

  function renderRole(item: ScenarioRole, scenario: RolePlayScenario) {
    return <ImageListItem key={item.imgFilename}>
      <img
        // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
        // src={`${item.img}?w=248&fit=crop&auto=format`}
        // src={`${API_SERVER}/tmp_scenarios/${item.imgFilename}`}
        src={`${API_SERVER}/static/img/scenarios/${scenario.id}/${item.imgFilename}`}
        alt={item.description}
        loading="lazy"
        style={
          selectedImage === item.imgFilename ? {border: "5px solid black"} : {}
        }
        onClick={() => handleClick(item)}
        // onClick={topicSelected}
      />
      <ImageListItemBar
        title={`${item.gender}, age ${item.age}`}
        subtitle={item.description}
        actionIcon={
          <ScenarioInfoPopover item={item} popoverRenderer={rolePopoverRenderer}/>
        }
      />
    </ImageListItem>;
  }

  // console.log('role selected scenario', scenario)

  return (
    // <ImageList
    //   // sx={{width: 500, height: 450}}
    // >

    <Box
      // sx={{ width: 500, minHeight: 829 }}
    >
      <Masonry columns={{xs: 1, sm: 2, md: 3}}
        // spacing={2}
      >
        {/*on responsiveness, see: https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size*/}
        {/*<ImageListItem key="Subheader" cols={2}>*/}
        {/*  <ListSubheader component="div">Pick a scenario</ListSubheader>*/}
        {/*</ImageListItem>*/}
        {scenario ? scenario.roles.map((item: ScenarioRole) => renderRole(item, scenario)) : null}
        {/*{renderRole(scenario.)}*/}
        {/*</ImageList>*/}
      </Masonry>
    </Box>
  );
}
