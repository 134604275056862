import React, {useEffect, useRef, useState} from "react";
import {chatMessageStyles} from "./chatMessageStyles";
import {useTheme} from "@mui/material/styles";
import {DeleteForever} from "@mui/icons-material";
import {LinearProgress, Stack, Box, Typography} from "@mui/material";
import {dateTimeToLongString, dateTimeToShortString} from "../uiUtils";
import AudioPlayer from "../MuiAudioPlayer";

export function ChatMessageRequestItem(props) {
  // NOTE: init with default values if needed
  const {
    requestItem
  } = props;

  const classes = chatMessageStyles();

  const [playing, setPlaying] = useState(requestItem.playing);
  // setPlaying: (value: (((prevState: boolean) => boolean) | boolean)) => void,

  const ref = useRef();

  // FIXME:
  // @ts-ignore
  const executeScroll = () => ref.current?.scrollIntoView({behavior: "smooth", block: "end"})

  useEffect(() => {
    if (ref.current) {
      // ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      executeScroll();
    }
  }, []);

  // console.log('requestItem: ', requestItem)

  function renderError() {
    return <Box
      className={`${classes.messageOrange} ${classes.messageError}`}
      // className={`messageError`}
      // sx={{color: theme.palette.secondary.contrastText}}
                ref={ref}
                id={'req_' + requestItem.ts.getTime()}>
      <Box>
        <Typography
          component="span">{requestItem.error}</Typography>
      </Box>

    </Box>;
  }

  function renderNormal() {
    return <Box
      className={classes.messageOrange}
      // sx={{color: theme.palette.secondary.contrastText}}
                ref={ref}
                id={'req_' + requestItem.ts.getTime()}>
      <Box>
        {/*<Typography component="span" sx={{fontWeight: 'fontWeightSemiBold'}}>{requestKey}</Typography>*/}
        {/*<Typography component="span"*/}
        {/*            sx={{fontWeight: 'fontWeightLight'}}>{requestItem.isProcessing ? " <PROCESSING> " : null} </Typography>*/}

        {requestItem.isProcessing ? <LinearProgress/> : null}
        <Typography component="span"
                    sx={{fontWeight: 'fontWeightLight'}}>{requestItem.requestTask ? requestItem.requestTask + ': ' : null} </Typography>
        <Typography
          component="span">{requestItem.requestText ? requestItem.requestText : requestItem.requestWavDataText ? requestItem.requestWavDataText : null}</Typography>
      </Box>

      {requestItem.urlEncodedWavRequest ?
        <Stack direction="row" spacing={2}
          // display="flex"
          // alignItems="center"
               sx={{
                 display: 'flex',
                 justifyContent: 'space-between',
                 alignItems: 'center',// p: 2
               }}
          // sx={{padding: '2px'}}
        >
          <AudioPlayer inline
            // style={{paddingTop: '3px'}}
                       id={'pl_it_req_' + requestItem.ts.getTime()}
                       src={requestItem.urlEncodedWavRequest}
                       showTimestamps={true}
                       display="waveform"
                       paperize={true}
            // playing={requestItem.playing}
                       playing={playing}
                       onPlay={() => {
                         // console.log('On play ', requestItem);
                         setPlaying(true);
                         requestItem.playing = true;
                       }
                       }
                       onFinished={() => {
                         // console.log('On finished ', requestItem)
                       }}
                       onPaused={() => {
                         // console.log('On paused ', requestItem)
                       }}
                       onNotPlaying={() => {
                         // console.log('onNotPlaying');
                         setPlaying(false);
                         requestItem.playing = false;
                       }}
          />
          <Typography component="span"
            // sx={{fontWeight: 'fontWeightLight'}}
            // variant="caption"
                      variant="body2"
          >{dateTimeToShortString(requestItem.ts.getTime())} </Typography>
        </Stack>
        : null}

      {/*<DeleteForever color="warning"/>*/}
    </Box>;
  }

  return requestItem.messageType === 'error' ? renderError() : renderNormal();
}
