import React, {useEffect, useRef, useState} from 'react';
import {showPopupInfoMessage} from "./components/PopupNotifications";
import {AppFrame, HEADER_HEIGHT} from "./components/AppFrame";
import {Box, Button, Grid, Link, Stack, Tooltip, Typography} from "@mui/material";
import {useAppStore} from "./state_store";
import update from "immutability-helper";
import makeStyles from '@mui/styles/makeStyles';

import {VoiceChat, Feedback, MailLock} from "@mui/icons-material";

import {
  play_cannot_accept_request_notification,
  play_message_accepted_for_processing_notification,
  play_processing_finished_notification
} from "./AudioNotifications";
import {socket} from "./api/BackendSocketApi";
import {BotomnSheet} from "./components/BotomnSheet";
import {ChatInputBar} from "./components/ChatInputBar";
import {ChatMessagesContainer} from "./components/chat/ChatMessagesContainer";
import {useTranslation} from "react-i18next";
import Chats from "./components/Chats";
import {JsonProperty, ObjectMapper} from "json-object-mapper";
import {
  updateChatPlayStatesOnNewSentenceArrived
} from "./audioLogicHandler";
import {StatusWidget} from "./components/StatusWidget";
import {SettingsAdditionalContent} from "./components/SettingsAdditionalContent";
import {CurrentChatInfoTitle} from "./components/CurrentChatInfoTitle";
import PersonalDashboardWinModalComponent, {LoginAppBarWidget} from "./components/PersonalDashboardWinModalComponent";
import {BackendRestApiInstance} from "./api/BackendRestApi";
import {useVadManager} from "./components/VadManager";
import {RequestMsg} from "./models/RequestMsg";
import {ResponseSentenceSchema} from "./models/ResponseSentenceSchema";

const useStyles = makeStyles((theme) => ({
  messageArea: {
    // height: '70vh',
    // overflowY: 'auto'

    // FIXME: use color from styles
    // border: "1px solid grey"
  },
}));


function gotNewMessagesToInactiveChat(responseMsg: RequestMsg) {
  console.log('No request msg found', responseMsg)
  console.log('We have:', useAppStore.getState().currentChatMessages)
  // TODO: replace with success upon case testing
  showPopupInfoMessage("You received a new message to an inactive chat. Don't worry, you can read it when you open the chat.", 'warning');
}

function Client(props) {
  const {mode, setMode, children, ...other} = props;

  const {t, i18n} = useTranslation();

  const refBottomnSheet = useRef();

  const classes = useStyles();

  const [showFooter, setShowFooter] = useState(false);
  const [appBarHeight, setAppBarHeight] = useState(false);

  const {inputForbiddenSignal, setInputForbiddenSignal} = useAppStore()
  const {currentChatMessages, setCurrentChatMessages, currentChat, setCurrentChat} = useAppStore()
  const {isConnected, setIsConnected} = useAppStore()
  const {isAuthFailed, setIsAuthFailed} = useAppStore()
  const {setCurrentPronunciationCheckInputAudio} = useAppStore()

  // to subscribe for changes
  const loginToken = useAppStore((state) => state.loginToken);

  useEffect(() => {
    const callback = (state, prevState) => {
      if (state.loginToken == prevState.loginToken) {
        return;
      }

      // console.log('loginToken, updated to state', state)

      // disconnect socket when auth changed
      socket.auth = {
        token: useAppStore.getState().loginToken
      }

      socket.disconnect()

      if (state.loginToken) {
        // connect only if we have an auth token
        socket.connect();
      }
    }
    const unsubscribe = useAppStore.subscribe(
      callback,
      // (state) => getSceneObject(props.id)(state)
    )
    // callback()
    return unsubscribe
  }, [loginToken])

  useEffect(() => {
    // console.log('audioList - audioList Has changed', JSON.stringify(audioList))
    // console.log('audioList - audioList Has changed (act)', JSON.stringify(useAppStore.getState().audioList))
  }, [currentChatMessages])

  function on_socket_connect_msg() {
    console.log('Socket connected')
    if (socket.recovered) {
      // any event missed during the disconnection period will be received now
      console.log('(recovered)')
    } else {
      // new or unrecoverable session
      console.log('(new or unrecoverable session)')
    }

    setIsConnected(true);
    setIsAuthFailed(false);
  }

  /***
   * https://socket.io/docs/v4/client-socket-instance/#connect-error
   * @param err
   */
  function on_socket_connect_error_msg(err: Error) {
    console.error('(connect_error)', err.message)

    // {"message":"Missing auth info"}
    // FIXME: do not rely on text, but rather on code - send the code.
    //  This is possible only if sending code as message, or encoding both in message
    if (err.message == 'Missing auth info' || err.message == 'Auth token incorrect or user does not exist') {
      // if (data == 401) {
      setIsAuthFailed(true);

      // TODO: disconnect socket when auth changed
      socket.auth = {
        token: useAppStore.getState().loginToken,
        // user_name: "[user]"
      }

      // socket.connect();
    }

    showPopupInfoMessage('Connection error: ' + err.message, 'error');
  }

  function on_socket_error_msg(data) {
    console.warn("'error' message. Got:", data);

    play_cannot_accept_request_notification();

    // FIXME: should we do that? in some cases - definitely. other cases?
    // setIsProcessingUserMessage(false)
    if (useAppStore.getState().currentChat) {
      useAppStore.getState().currentChat.isProcessingUserMessage = false;
    }

    // let messagesCurrent = useAppStore.getState().messages
    // const newData = update(messagesCurrent, {
    //   [data.request_idx]: {
    //     requestText: {$set: `[Message processing error. Try again. Details: ${data.error}]`},
    //     isProcessing: {$set: false}
    //   }
    // });
    // setMessages(newData)

    // TODO: highlight the request message in chat
    showPopupInfoMessage('Unhandled error. Try again. Details: ' + data, 'error');
  }

  function on_socket_disconnect_msg(reason: "io server disconnect" | "io client disconnect" | "ping timeout" | "transport close" | "transport error" | "parse error", details: Error | { description: string; context?: unknown }) {
    console.log('Socket disconnect', reason, details)

    // // the low-level reason of the disconnection, for example "xhr post error"
    // console.log(details.message);
    //
    // // some additional description, for example the status code of the HTTP response
    // console.log(details.description);
    //
    // // some additional context, for example the XMLHttpRequest object
    // console.log(details.context);

    setIsConnected(false);
  }

  function on_socket_response_message_sentence_msg(data) {
    // console.log('response_message_sentence: ', data)

    let responseMsg: RequestMsg = ObjectMapper.deserialize(RequestMsg, data);
    let messagesCurrent: Record<number, RequestMsg> = useAppStore.getState().currentChatMessages;

    let curRequestEntity = messagesCurrent[responseMsg.replyToTsId.getTime()];
    if (!curRequestEntity) {
      gotNewMessagesToInactiveChat(responseMsg);
      return;
    }

    // note: not modifying .isProcessing since we do not know whether processing has been finished
    // FIXME: interface, schema control
    curRequestEntity.requestText = responseMsg.replyToText;
    curRequestEntity.requestTask = responseMsg.replyToTask;

    let updatedMessages: Record<number, RequestMsg> = updateChatPlayStatesOnNewSentenceArrived(messagesCurrent,
      responseMsg, curRequestEntity);

    // messagesCurrent = update(messagesCurrent, {
    //   [responseMsg.ts.getTime()]: {$set: responseMsg}
    // });

    updatedMessages[responseMsg.ts.getTime()] = responseMsg;
    updatedMessages[curRequestEntity.ts.getTime()] = curRequestEntity;

    setCurrentChatMessages(update(messagesCurrent, {$set: updatedMessages}))
  }

  function on_socket_response_message_finished_msg(data) {
    // console.log("response_message_finished. got:", data);

    // FIXME: deserialize a finalization object
    // let responseMsg: ResponseMsgFinished = ObjectMapper.deserialize(ResponseMsgFinished, data);
    let responseMsg: RequestMsg = ObjectMapper.deserialize(RequestMsg, data);
    // const scenarios: RolePlayScenario[] = ObjectMapper.deserializeArray(RolePlayScenario, data.data);
    // for (let i = 0; i < scenarios.length; i++) {
    //   const scenario = scenarios[i];
    for (let j = 0; j < responseMsg.responseOptions.length; j++) {
      const o = responseMsg.responseOptions[j];
      // console.log(role)
      const val = ObjectMapper.deserialize(ResponseSentenceSchema, o);
      // console.log('deser role', val)
      responseMsg.responseOptions[j] = val;
    }
    // }

    // console.log("response_message_finished. deserialized:", responseMsg);

    // FIXME: should not we comment out the notification sound? we just automatically start playing the arrived sentence?
    // A: no, all the sentences have been played already. this message. marks the end and unblock input. not messages will arrive futher
    // arrived
    // notifications on: busy, command accepted, command finished
    play_processing_finished_notification();

    let response_options_sent = []
    if (responseMsg.responseOptions) {
      for (let i = 0; i < responseMsg.responseOptions.length; i++) {
        let o = responseMsg.responseOptions[i];
        let opt: RequestMsg = new RequestMsg();
        opt.userCategory = responseMsg.userCategory
        opt.ts = responseMsg.ts
        opt.replyToTsId = null //responseMsg.replyToTsId
        opt.lessonId = responseMsg.lessonId
        opt.messageType = responseMsg.messageType

        opt.translation = o.translation
        opt.ipa = o.ipa
        opt.lang = o.lang
        opt.requestText = o.text

        response_options_sent.push(opt)
      }
      // <ChatMessageSentenceItem sentenceItem={requestItem} vad={vad}
      //                          stopChatPlayback={stopChatPlayback}
      //                          setAppSoundMode={setAppSoundMode}
      //                          playNextMessageIf={playNextMessageIf}
      //                          getMessageIsPlaying={getMessageIsPlaying}
      //                          chatMessagesIsPlaying={chatMessagesIsPlaying}
      //                          setChatMessagesPlaying={setChatMessagesPlaying}
      // />
    }

    // console.log('response_options_sent', response_options_sent)

    useAppStore.getState().setResponseOptions(response_options_sent);

    // FIXME: error handling. and make sure to change setIsProcessingUserMessage to a queue of ids, popping them out when done.
    // the normal operation would mean we only have 0 or 1 element in a queue
    // to test it , just shut the server down or disconnect the Internet
    if (useAppStore.getState().currentChat) {
      useAppStore.getState().currentChat.isProcessingUserMessage = false;
    }

    let messagesCurrent: Record<number, RequestMsg> = useAppStore.getState().currentChatMessages;

    // if (messagesCurrent[data.request_idx] !== undefined) {
    //   //request_idx?? // insert based ot request start time - .ts
    // }

    const id = responseMsg.replyToTsId.getTime();
    if (!messagesCurrent[id]) {
      gotNewMessagesToInactiveChat(responseMsg);
      return;
    }

    const newData: Record<number, RequestMsg> = update(messagesCurrent, {
      [id]: {
        requestText: {$set: responseMsg.replyToText},
        requestTask: {$set: responseMsg.replyToTask},
        isProcessing: {$set: false}
      }
    });
    setCurrentChatMessages(newData)
  }

  function on_socket_response_message_error_msg(data) {
    // console.warn("response_message_error message. Got:", data);

    play_cannot_accept_request_notification();

    let responseMsg: RequestMsg = ObjectMapper.deserialize(RequestMsg, data);

    // FIXME: error handling. and make sure to change setIsProcessingUserMessage to a queue of ids, popping them out when done.
    // the normal operation would mean we only have 0 or 1 element in a queue
    // to test it , just shut the server down or disconnect the Internet
    // setIsProcessingUserMessage(false)
    if (useAppStore.getState().currentChat) {
      useAppStore.getState().currentChat.isProcessingUserMessage = false;
    }

    // TODO: highlight the request message in chat
    // Cannot read properties of undefined (reading 'requestText'
    // using useAppStore.getState().audioList (instead messages directly) to avoid this error
    let messagesCurrent: Record<number, RequestMsg> = useAppStore.getState().currentChatMessages
    const id = responseMsg.replyToTsId.getTime();
    // const id = responseMsg.replyToTsId ? responseMsg.replyToTsId.getTime() : responseMsg.ts.getTime();
    if (!messagesCurrent[id]) {
      gotNewMessagesToInactiveChat(responseMsg);
      return;
    }

    // console.log('on_socket_response_message_error_msg: ', id, useAppStore.getState().currentChatMessages[id])

    const newData: Record<number, RequestMsg> = update(messagesCurrent, {
      [id]: {
        requestText: {$set: `[Message processing error. Try again. Details: ${responseMsg.error}]`},
        requestTask: {$set: responseMsg.replyToTask},
        isProcessing: {$set: false},
      }
    });
    setCurrentChatMessages(newData)

    // console.error(data)

    // FIXME: the object is not transformed, thus the naming convention is snake case
    if (data.error_code === 402) {
      // not enough funds
      // actualize the profile (funds amount in it)
      BackendRestApiInstance.fetchUserDataAPI();
      // and show it
      useAppStore.getState().setShowDashboardWindow(true);
    } else {
      showPopupInfoMessage('Message processing error. Try again. Details: ' + data.error, 'error');
    }
  }

  useEffect(() => {
    console.log("client, useEffect called")

    // socket.of("/chat").on('connect', () => {
    socket.on('connect', on_socket_connect_msg);
    socket.on('connect_error', on_socket_connect_error_msg);
    socket.on('error', on_socket_error_msg);
    socket.on('disconnect', on_socket_disconnect_msg);
    socket.on('response_message_sentence', on_socket_response_message_sentence_msg);
    socket.on('response_message_finished', on_socket_response_message_finished_msg);
    socket.on('response_message_error', on_socket_response_message_error_msg);

    // console.log("react component socket initialization")

    // no-op if the socket is already connected
    if (useAppStore.getState().loginToken) {
      // connect only if we have a login token
      socket.connect();
    }

    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('disconnect');
      socket.off('error');
      socket.off('response_message_sentence');
      socket.off('response_message_finished');
      socket.off('response_message_error');
    };
  }, []);

  const vad = useVadManager();

  function renderStatusWidget() {
    return <StatusWidget vad={vad}/>;
  }

  function renderToolbar() {
    const onNewChat = () => {
      // console.debug('createChat on click')
      // BackendRestApiInstance.createChat(languageToLearn, level, nativeLanguage, teacher);

      // THIS will trigger the New chat form
      // important to set [] instead of null - since current code does not handle the first case
      useAppStore.getState().setCurrentChatMessages({});
      useAppStore.getState().setCurrentChat(null, null, false);
      useAppStore.getState().setCurrentChatType('default');
    }

    // function onNewPronunciationTraining() {
    //   useAppStore.getState().setCurrentChatMessages(new Record<number, RequestMsg>());
    //   useAppStore.getState().setCurrentChat(null, null, false);
    //   useAppStore.getState().setCurrentChatType('pronunciation');
    // }

    return <Stack direction="row"
      // container
                  justifyContent="left"
    >
      {/*{renderStatusWidget()}*/}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/*we need the box to vertically align the contained text*/}
        <CurrentChatInfoTitle/>
      </Box>

      <Chats/>

      <Tooltip title={'New lesson/chat'} enterDelay={300}>
        <Button sx={{mx: 1}}
                size="small" onClick={onNewChat}
                startIcon={<VoiceChat fontSize="small"/>}
          // IMPORTANT: the 'contained' style give a goof CTA vibe
                variant="contained"
        >
          {/*<VoiceChat fontSize="small"/> */}
          {/*<Typography*/}
          {/*  sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}*/}
          {/*> New lesson</Typography>*/}

          <Typography
            sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}
          > New </Typography>
          {/*> New lesson</Typography>*/}

          <Typography
            sx={{display: {xs: 'block', sm: 'none'}}}
          > New </Typography>

        </Button>
      </Tooltip>

      {/*import TranslateIcon from '@mui/icons-material/Translate';*/}

      {/*<Tooltip title={'New pronunciation training'} enterDelay={300}>*/}
      {/*  <Button size="small" onClick={onNewPronunciationTraining}>New pronunciation training</Button>*/}
      {/*</Tooltip>*/}

    </Stack>;
  }

  function renderSettingsContent() {
    return <>
      <PersonalDashboardWinModalComponent
        // align='right'
      />

      <Link
        // display="flex"
        target="_blank"
        color="inherit"
        data-attr="settings:feedback_link"
        href="https://tothecut.youtrack.cloud/form/fb2603d4-9b3e-4e99-a775-c328e5dc2249">
        {/*<Typography variant="body2" color="textSecondary" display="flex">*/}

        {/*Not: flex in the container - to avoid icon and email breaking to different lines*/}
        <Feedback style={{verticalAlign: "middle"}}/>
        Support and feedback
        {/*</Typography>*/}
      </Link>

      {SettingsAdditionalContent({mode, setMode})}
    </>
  }

  // function MainContent() {
  //   return <Box>
  //     <Grid container spacing={0} columns={12}>
  //       <Grid item xs={1} lg={2}>
  //         {/*contacts or something else*/}
  //       </Grid>
  //
  //       <Grid item xs={10} lg={8} className={classes.messageArea}>
  //         <ChatMessagesContainer HEADER_HEIGHT={HEADER_HEIGHT} refBottomnSheet={refBottomnSheet}
  //                                vad={vad}
  //                                messages={currentChatMessages} setMessages={setCurrentChatMessages}/>
  //       </Grid>
  //
  //       <Grid item xs={1} lg={2}>
  //         {/*contacts or something else*/}
  //       </Grid>
  //     </Grid>
  //   </Box>;
  // }

  return (
    <Box sx={{flexGrow: 1}}>
      <AppFrame
        additionalHeaderLeftContent={renderStatusWidget()}
        additionalHeaderButtons={renderToolbar()}
        BannerComponent={null}
        setAppBarHeight={setAppBarHeight}
        menuButtonAdditionalContent={LoginAppBarWidget(null)}
        // settingsAdditionalChildren={SettingsAdditionalContent({mode, setMode})}
        settingsAdditionalChildren={renderSettingsContent()}
      >
        {/*space keeper*/}
        <Box style={{height: `${appBarHeight}px`}}/>
        {/*<MainContent/>*/}

        <Box>
          <Grid container spacing={0} columns={12}>
            <Grid item xs={1} lg={2}>
              {/*contacts or something else*/}
            </Grid>

            <Grid item xs={10} lg={8}
              // className={classes.messageArea}
            >
              <ChatMessagesContainer appBarHeight={appBarHeight} refBottomnSheet={refBottomnSheet}
                                     vad={vad}
                                     messages={currentChatMessages} setMessages={setCurrentChatMessages}/>
            </Grid>

            <Grid item xs={1} lg={2}>
              {/*contacts or something else*/}
            </Grid>
          </Grid>
        </Box>

        {/*<Routes>*/}
        {/*  <Route exact path="" component={MainContent}/>*/}
        {/*  <Route exact path="/about" component={MainContent}/>*/}
        {/*  <Route path="/about" component={About} />*/}
        {/*  <Route path="/contact" component={Contact} />*/}
        {/*  <Route*/}
        {/*    path='create'*/}
        {/*    element={(*/}
        {/*      <Dialog open>*/}
        {/*        <CreateCustomer />*/}
        {/*      </Dialog>*/}
        {/*    )}*/}
        {/*  />*/}
        {/*  <Route component={NotFoundPage} />*/}
        {/*</Routes>*/}

        <BotomnSheet refBottomnSheet={refBottomnSheet} showFooter={showFooter}
                     setShowFooter={setShowFooter}>
          {currentChat ? <ChatInputBar vad={vad}/> : null}

        </BotomnSheet>

      </AppFrame>
    </Box>
  )

}

export default Client;
