import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {deepOrange} from "@mui/material/colors";

/**
 * see: https://codesandbox.io/p/sandbox/material-ui-chat-drh4l?file=%2Fsrc%2FMessage.js%3A123%2C2-123%2C31
 */
export const chatMessageStyles = makeStyles((theme: Theme) =>
  // FIXME: some of the chat-related styles are in index.css . Find the best way to merge both
  createStyles({
    messageRow: {
      display: "flex"
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end"
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      // marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#A8DDFD",
      color: "#000000de",
      // width: "60%",
      width: "auto",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #97C6E3",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #A8DDFD",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #97C6E3",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px"
      }
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      // marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#f8e896",
      color: "#000000de",
      // width: "60%",
      width: "auto",
      //height: "50px",
      textAlign: "left",
      // font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #dfd087",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #f8e896",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #dfd087",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"

        // // backgroundColor: '#F2F2F2',
        // border: "1px solid #dfd087",
        // // content: "\00a0",
        // content: "''",
        // display: 'block',
        // height: '16px',
        // width:  '20px',
        // position: 'absolute',
        // // top: '11px',
        // top: '0px',
        // transform:             'rotate( 29deg ) skew( -35deg )',
        // mozTransform:    'rotate( 29deg ) skew( -35deg )',
        // msTransform:     'rotate( 29deg ) skew( -35deg )',
        // oTransform:      'rotate( 29deg ) skew( -35deg )',
        // webkitTransform: 'rotate( 29deg ) skew( -35deg )',

      }
    },

    messageError: {
      backgroundColor: "#f80000",
    },

    messageContent: {
      padding: 0,
      margin: 0
    },

    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "-3px",
      right: "5px"
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4)
    },

    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4)
    },

    displayName: {
      marginLeft: "20px"
    }
  })
);
