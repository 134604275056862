import {CacheKey, Deserializer, JsonIgnore, JsonProperty, ObjectMapper} from "json-object-mapper";
import {ResponseSentenceSchema} from "./ResponseSentenceSchema";

//
// @CacheKey("ResponseSentencesDeserializer")
// class ResponseSentencesDeserializer implements Deserializer {
//   deserialize = (value: any): any => {
//     let mapToReturn: { [id: string] : ResponseMsg; } = {};
//     if (value) {
//       Object.keys(value).forEach((key: string) => {
//         mapToReturn[key] = ObjectMapper.deserialize(ResponseMsg, value['' + key])
//       });
//     }//{ [id: string] : ResponseMsg; }
//     return mapToReturn;
//   }
// }


/**
 * usage: let responseMsg: ResponseMsg = ObjectMapper.deserialize(ResponseMsg, data);
 */
export class RequestMsg {
  @JsonProperty({type: String, name: "lesson_id"})
  lessonId: string;
  @JsonProperty({type: Number, name: "request_idx"})
  requestIdx: number;

  @JsonProperty({type: String, name: "text"})
  requestText: string;
  // @JsonProperty({type: String, name: "text"})
  // text: string;
  @JsonProperty({type: String, name: "lang"})
  lang: string;
  @JsonProperty({type: String, name: "translation"})
  translation: string;
  @JsonProperty({type: String, name: "ipa"})
  ipa: string;

  @JsonProperty({type: String, name: "wav_data_text"})
  requestWavDataText: string;
  @JsonProperty({type: String, name: "task"})
  requestTask: string;
  @JsonProperty({type: Date})
  ts: Date;
  @JsonProperty({type: String, name: "wav_data"})
  urlEncodedWavRequest: string;
  @JsonProperty({type: Boolean, name: "respond_with_voice"})
  respondWithVoice: boolean;

  /**
   * Id is used to sort objects chronologically. Yes, id is a string. ATM backend has a sentence idx as a nid
   */
    // @JsonProperty({deserializer: ResponseSentencesDeserializer, name: "response_sentences"})
    // responseSentences: { [id: string] : ResponseMsg; };

    // request idx/id to reply to
  @JsonProperty({type: String, name: "reply_to_text"})
  replyToText: string = null;
  @JsonProperty({type: String, name: "reply_to_task"})
  replyToTask: string = null;
  @JsonProperty({type: Date, name: "reply_to_ts_id"})
  replyToTsId: Date = null;
  @JsonProperty({type: Number, name: "reply_to"})
  replyTo: number = -1;
  @JsonProperty({type: Boolean, name: "were_mentioned"})
  wereMentioned: boolean = false;
  @JsonProperty({type: String, name: "user_category"})
  userCategory: string = 'me';
  @JsonProperty({type: String, name: "message_type"})
  messageType: string = 'message';

  @JsonIgnore()
  isProcessing: boolean = false;
  @JsonIgnore()
  playing: boolean = false;

  @JsonProperty({type: String, name: "error"})
  error: string;

  @JsonProperty({type: Number, name: "error_code"})
  errorCode: number;

  //////
  @JsonProperty({type: Date, name: "received_at"})
  receivedAt: Date;

  // # Optional[int
  // user_id=old_data.user_id,

  @JsonProperty({type: String, name: "reviewed_writing"})
  reviewedWriting: string = null;

  // @JsonProperty({type: Array<ResponseSentenceSchema>, name: "response_options"})
  @JsonProperty({name: "response_options"})
  responseOptions: ResponseSentenceSchema[] = null;
}
