import React, {useEffect} from "react"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './index.css';
//import './components/chat/chatMessageStylesV2.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
// import { getPerformance } from "firebase/performance";
// import {getAnalytics} from "firebase/analytics";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";

import config from "./config/config";

import * as serviceWorker from './serviceWorker';
import {SnackbarProvider} from "notistack";
import Button from '@mui/material/Button';
import {CssBaseline} from "@mui/material";
import {render} from "react-dom";

import './i18n/config';

import BrandingCssVarsProvider from "./theme/BrandingCssVarsProvider";
import BrandingProvider from "./theme/BrandingProvider";
import Client from "./Client";
import useLocalStorageState from "./utils/useLocalStorageState";
import {BackendRestApiInstance, setLastChatAsCurrent} from "./api/BackendRestApi";
import {useAppStore, useSettingsStore} from "./state_store";
import {AuthDialog} from "./components/AuthDialogAndFunctions";
import {DashboardWindow, initiateLoginSequence} from "./components/PersonalDashboardWinModalComponent";
import MediaOnboardingDialog from "./components/MediaOnboardingDialog/MediaOnboardingDialog";
import TopUpBalanceDialog from "./components/TopUpBalanceDialog";
import AccountDeletionDialog from "./components/AccountDeletionDialog";
// import {TopicList} from "./components/TopicList";

import {PostHogProvider} from 'posthog-js/react'
import reportWebVitals from './reportWebVitals';
import NotFound from "./components/NotFoundPage";
import {showPopupInfoMessage} from "./components/PopupNotifications";
// import {ScenarioList} from "./components/ScenarioList";
import ScenarioSelectionFlow from "./components/ScenarioSelectionFlow";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  enable_recording_console_log: true,
  enable_heatmaps: true,
  disable_session_recording: false,

  // FIXME: not mentioned in docs directly.
  capture_performance: true
}

// add action to all snackbars
const notistackRef: React.RefObject<any> = React.createRef();
const onClickDismiss = (key: any) => () => {
  notistackRef.current.closeSnackbar(key);
}

//
// const domContainer = document.querySelector("#root")
// const root = createRoot(document.getElementById("root"))
const root = document.getElementById("root");

const firebaseApp = firebase.initializeApp(config.firebase);
// const analytics = getAnalytics(firebaseApp);
// const analytics = firebase.analytics(firebaseApp);
const analytics = firebase.analytics(firebaseApp);
const performance = firebase.performance(firebaseApp);

analytics.logEvent('App loaded');
// firebase.auth().useDeviceLanguage(); // set with function
// firebase.auth().languageCode = 'ru'; // set with string

function launchDataDeletionScenario() {
  useAppStore.getState().setAccountDeletionDialogOpen(true)
}

function isLoginTokenPresent() {
  /*
  NOTE: the token could be expired. token expiration is detected when an authed API call fails with a relevant error code
   */
  console.log('loginToken', useAppStore.getState().loginToken)
  if (useAppStore.getState().loginToken) {
    console.log('have loginToken')
    return true
  }

  return false;

  // return Boolean(useAppStore.getState().loginToken);
}

function fetchUserData() {
  // todo: add state: haveLoginToken = isLoginTokenPresent();
  try {
    // Swallow the exceptions, e.g. rate limit
    // FIXME: do same on log-in/out events

    // console.log('fetchLastChatOnPageRefresh ', useSettingsStore.getState().fetchLastChatOnPageRefresh )
    // console.log('have token: ', useAppStore.getState().loginToken )
    const haveLoginToken = isLoginTokenPresent();
    // console.log('have token: ', haveLoginToken)
    // console.log('token: ', useAppStore.getState().loginToken)
    // console.log('have process.env.DEV_INIT_LOGIN_TOKEN token: ', process.env.DEV_INIT_LOGIN_TOKEN )
    //
    if (haveLoginToken) {
      BackendRestApiInstance.getChats(
        useSettingsStore.getState().fetchLastChatOnPageRefresh ? setLastChatAsCurrent : null
      );
    }

    // FIXME: REMOVE IT
    BackendRestApiInstance.getScenarios()

    // Firebase UI only works on the Client. So we're loading the package in `componentDidMount`
    // So that this works when doing server-side rendering.
    //const firebaseui = require('firebaseui');

    // firebase.auth()

    // data deletion url
    //const url = window.location.href;
    // const pathname = window.location.pathname;

    // // if (pathname == '/data_deletion') {
    // //   launchDataDeletionScenario()
    // // }
  } catch (e) {

  }
}


function DataDeletion(elt) {
  // let formData = await request.formData();
  console.log('data_deletion Entered');
  launchDataDeletionScenario()
  // let location = useLocation();

  return (<Navigate to="/"
    // state={{ from: location }}
                    replace/>)

  // return elt
}


const DoSomething = (props) => {

  const {
    showLoginWindow,
    setShowLoginWindow,
    loginToken,
  } = useAppStore()

  // trigger on component mount
  useEffect(() => {
    //posting arg1 as an example of whatever you are wanting to do.

    const loginTokenPresent = isLoginTokenPresent();

    // console.log('loginTokenPresent', loginTokenPresent);
    // console.log('loginToken, from state', loginToken);
    // console.log('loginToken, from store', useAppStore.getState().loginToken);

    // NOTE: login token might arrive later
    if (loginTokenPresent) {
       // TODO: try to login in order to make sure the token is not expired
      showPopupInfoMessage('You are already logged in', 'success');
      return
    }

    setShowLoginWindow(true)
    // initiateLoginSequence();

    // props.setAuthenticated(true);fontFamily
  }, []);


  // initiateLoginSequence();

  // return (<Navigate to="/"
  //   // state={{ from: location }}
  //                   replace/>)
  // setShowLoginWindow(true)

  // initiateLoginSequence()
  return props.component;
};
// <Route path="/" element={doSomething("string", <Home />)} />


// function DataDeletion(elt) {
//   // let formData = await request.formData();
//   console.log('data_deletion Entered');
//   launchDataDeletionScenario()
//   // let location = useLocation();
//
//   return (<Navigate to="/"
//     // state={{ from: location }}
//                     replace/>)
//
//   // return elt
// }


// let HashRoute = ({ component: Component, path, ...routeProps }) => (
//   <Route
//     {...routeProps}
//     element={({ location, ...props }) =>
//       location.hash === path && <Component {...props} />
//     }
//   />
// )

function Base() {
  const [mode, setMode] = useLocalStorageState('theme', 'system');

  useEffect(() => {
    // run startup tasks
    // console.log('Root component mounted')
    fetchUserData();
  }, []);

  function Main() {
    return <>
      <Client mode={mode} setMode={setMode}/>
      <DashboardWindow/>
      <TopUpBalanceDialog/>
      <AccountDeletionDialog/>
      {/*<AuthDialog/>*/}
      <MediaOnboardingDialog/>
      <Outlet/>
    </>;
  }

  return <BrandingCssVarsProvider>
    {/*@ts-ignore*/}
    <BrandingProvider mode={mode}>

      {/*<ThemeProvider theme={appTheme}>*/}
      <CssBaseline/>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={4000}
          maxSnack={3}
          action={(key) => (
            <Button onClick={onClickDismiss(key)}>Close</Button>
          )}>

          <AuthDialog/>
          <BrowserRouter>
            <Routes>
              {/*<Suspense fallback={<Loading />}>*/}
              {/*  <SomeComponent />*/}
              {/*</Suspense>*/}

              <Route path='/' element={<Main/>}>
                {/*<Route path='/login_flow' element={<DoSomething component={<React.Fragment/>}/>}/>*/}
              </Route>
              <Route path='/login_flow' element={<DoSomething component={<Navigate to="/"
                                                  // state={{ from: location }}
                                                                                   replace/>}/>}/>

              <Route
                path="/data_deletion"
                // component={App}
                // onEnter={() => console.log('Entered /')}
                // action={async ({ params, request }) => {
                //   // let formData = await request.formData();
                //   console.log('data_deletion Entered');
                //   launchDataDeletionScenario()
                // }}
                element={
                  <DataDeletion/>
                }
              />

              {/*<Route path="topics" element={<ScenarioSelectionFlow/>}/>*/}
              <Route path="notfound" element={<NotFound status={404}/>}/>
              <Route path="*" element={<Navigate to="/notfound" replace/>}/>

              {/*  <Route exact path="" component={MainContent}/>*/}
              {/*  <Route exact path="/about" component={MainContent}/>*/}
              {/*  <Route path="/about" component={About} />*/}
              {/*  <Route path="/contact" component={Contact} />*/}
              {/*  <Route*/}
              {/*    path='create'*/}
              {/*    element={(*/}
              {/*      <Dialog open>*/}
              {/*        <CreateCustomer />*/}
              {/*      </Dialog>*/}
              {/*    )}*/}
              {/*  />*/}
              {/*  <Route component={NotFoundPage} />*/}

            </Routes>
            {/*{Main()}*/}
          </BrowserRouter>

        </SnackbarProvider>
      </PostHogProvider>
      {/*</ThemeProvider>*/}
    </BrandingProvider>
  </BrandingCssVarsProvider>
}

render(
  <React.StrictMode>
    <Base></Base>
  </React.StrictMode>
  ,
  root
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
