import React from "react";

import {useAppStore} from "../state_store";
import {dateTimeToLongString, formatDate} from "./uiUtils";
import {deleteAccount} from "./AuthDialogAndFunctions";
// import {renderTaskTable} from "./renderTaskTable";
import {
  Alert,
  Badge,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link, MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import {AddShoppingCart, VoiceChat} from "@mui/icons-material";
import {Profile} from "../models/Profile";
import {profileImageUrl, replaceProfileImage} from "./profileImageUtils";
import {useTheme} from "@mui/material/styles";


const dashboardContentPhotoContainerStyle = {
  // backgroundColor: "#EEE",
  border: "1px solid #CCC",
  // float: "left",
  marginRight: "10px",
  maxHeight: "66px",
  maxWidth: "66px"
};
const dashboardContentPhotoImgStyle = {
  display: "inline-block",
  // height: "42px",
  // width: "42px",
  height: "32px",
  width: "32px",
};

function CreditsDisplayText(data: Profile) {
  // const theme = useTheme();

  // return data.creditsCents !== undefined ? data.creditsCents : '♾️';

  // let color = data.creditsCents <= 0 ? notOkColor : okColor;
  // let color = data.creditsCents <= 0 ? theme.palette.error.main : theme.palette.success.main;
  return <Typography
    variant="h6"
    // color=color
    // style={{color: color}}
  >
    {/*{data.creditsCents.toFixed(1)}*/}
    {data.creditsCents.toFixed(0)}
  </Typography>
}

export function PersonalDashboardContentComponent() {
  const {
    apiUserInfo, setApiUserInfo,
    paymentDialogOpen,
    setPaymentDialogOpen,
  } = useAppStore()

  let data: Profile = apiUserInfo;

  const handleClickOpen = () => {
    setPaymentDialogOpen(true);
  };

  if (!data) {
    return <div style={{height: "100%", width: "100%"}}>
      Failed to fetch account information.
    </div>;
  } else {
    // console.debug(data);

    const photoURL = profileImageUrl(data);

    // let badgeColor = data.creditsCents <= 0 ? 'error' : 'warning';
    let badgeColor: "error" | "default" | "success" | "warning" | "info" | "primary" | "secondary" = data.creditsCents <= 0 ? 'error' : 'success';

    return <div style={{
      // fontSize: "1.0rem",
      clear: "both",
      textAlign: "left",
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {data.creditsCents <= 0 ? <Alert severity="warning">
            {/*TODO: reserach variant="filled", and "outlined also"*/}
            <Typography>You have spent all the credits.</Typography>

            <Link color="inherit"
                  target="_blank"
                  data-attr="footer:feedback_link"
                  href="https://tothecut.youtrack.cloud/form/fb2603d4-9b3e-4e99-a775-c328e5dc2249">
              Request a top up here
            </Link>

            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  size="small"*/}
            {/*  // variant="outlined"*/}
            {/*  onClick={handleClickOpen}*/}
            {/*  startIcon={<AddShoppingCart fontSize="small"/>}*/}
            {/*>*/}
            {/*  <Typography*/}
            {/*    sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}*/}
            {/*  > Top up</Typography>*/}
            {/*</Button>*/}

          </Alert> : null}
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack direction='row'>
            <div id="photo-container" style={{
              // backgroundColor: "#EEE",
              border: "1px solid #CCC",
              float: "left",
              marginRight: "10px",
              maxHeight: "66px",
              maxWidth: "66px"
            }}>
              <img style={{
                display: "inline-block",
                height: "64px",
                width: "64px",
              }}
                   alt='Account profile image'
                   src={photoURL} id="photo"
                   onError={replaceProfileImage}/>
            </div>

            <Stack direction='column'>
              <Typography>Your name: {data.name}</Typography>
              <Typography>Email: {data.verifiedEmail}</Typography>
              <Typography>Joined: {dateTimeToLongString(data.createdAt)}</Typography>
              {/*<Typography>Active: {formatDate(data.lastSeenAt)}</Typography>*/}

              {/*<FormControl variant="standard" sx={{m: 1, minWidth: 80}}>*/}
              {/*  <InputLabel id="level-label">Gender</InputLabel>*/}
              {/*  <Select*/}
              {/*    labelId="level-label"*/}
              {/*    // id="demo-simple-select-standard"*/}
              {/*    value={gender}*/}
              {/*    onChange={(event: SelectChangeEvent) => setGender(event.target.value)}*/}
              {/*    label="Level"*/}
              {/*  >*/}
              {/*    <MenuItem value={'Female'}>Female</MenuItem>*/}
              {/*    <MenuItem value={'Male'}>Male</MenuItem>*/}
              {/*    <MenuItem value={'Other'}>Other</MenuItem>*/}
              {/*  </Select>*/}
              {/*</FormControl>*/}

              {/*<Typography>Your data will be used for better interactions with the teacher. This way we can help you learn faster.</Typography>*/}

              {/*<Button>Save changes</Button>*/}
            </Stack>
          </Stack>

        </Grid>
        <Grid item xs={0} md={0} lg={true}></Grid>
        <Grid item xs={12} md={3}>
          <Stack height='100%'
                 justifyContent='space-between'
                 direction="column">

            {/*<Box fullWidth>*/}
            <Typography
            > Your funds:</Typography>

            <Badge badgeContent={CreditsDisplayText(data)}
                   anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                   }}
              // fullWidth
                   sx={{width: '100%'}}
                   overlap="circular"
                   max={999}
                   color={badgeColor}>
              <Button
                variant="contained"
                // variant="outlined"
                onClick={handleClickOpen}
                startIcon={<AddShoppingCart/>}
              >
                <Typography
                > Add funds</Typography>
              </Button>
            </Badge>
            {/*</Box>*/}

            {/*<Button  onClick={() => deleteAccount()}>*/}
            {/*  Delete account*/}
            {/*</Button>*/}
          </Stack>
        </Grid>
      </Grid>

      {/*<Typography component={'h4'} variant={'h4'}>Tasks and assets</Typography>*/}
      {/*{renderTaskTable(data.customer.jobs)}*/}

      <div className="clearfix"></div>

    </div>
  }
}
