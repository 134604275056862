import {JsonIgnore, JsonProperty} from "json-object-mapper";


export class ScenarioRole {
  @JsonProperty({type: String, name: "gender"})
  gender: string;
  @JsonProperty({type: Number, name: "age"})
  age: number;
  @JsonProperty({type: String, name: "description"})
  description: string;
  @JsonProperty({type: String, name: "img_filename"})
  imgFilename: string;
}
//
// export class ScenarioRoleWithImage {
//   role: ScenarioRole;
//   image: string;
// }

/**
 * usage: let responseMsg: ResponseMsg = ObjectMapper.deserialize(ResponseMsg, data);
 */
export class RolePlayScenario {
  @JsonProperty({type: String, name: "id"})
  id: string;
  @JsonProperty({type: String, name: "title"})
  title: string;
  @JsonProperty({type: String, name: "catchy_title"})
  catchy_title: string;
  @JsonProperty({type: String, name: "catchy_slogan"})
  catchy_slogan: string;
  @JsonProperty({type: String, name: "context"})
  context: string;
  @JsonProperty({type: String, name: "location"})
  location: string;
  @JsonProperty({type: String, name: "topic"})
  topic: string;
  @JsonProperty({type: String, name: "category"})
  category: string;

  // @JsonProperty({type: Array<ScenarioRole>, name: "roles"})
  // roles: ScenarioRole[];

  @JsonProperty({name: "roles"})
  roles: ScenarioRole[];

  // @JsonProperty({type: String, name: "role_1_img_filename"})
  // role1ImgFilename: string;
  // @JsonProperty({type: String, name: "role_2_img_filename"})
  // role2ImgFilename: string;
  @JsonProperty({type: String, name: "img_filename"})
  imgFilename: string;
}

