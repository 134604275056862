import * as React from 'react';
import {useState} from 'react';
import {styled} from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {IconButton} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import {useAppStore} from "../state_store";
import {RolePlayScenario} from "../models/RolePlayScenario";
import {API_SERVER} from "../api/ApiEndpoints";
import ScenarioInfoPopover from "./ScenarioInfoPopover";

function scenarioPopoverRenderer(item) {
  return <>
    <Typography
      variant='h2'
      // sx={{ p: 2 }}
    >{item.title}</Typography>
    <Typography
      variant='subtitle1'
      // sx={{ p: 2 }}
    >Location: {item.location}</Typography>
    <Typography
      sx={{pt: 2}}
    >{item.context}</Typography>
  </>;
}

export function ScenarioList(props) {
  const {setSelectedScenario, stepMayChange, setStepMayChange} = props

  const {
    scenarios
  } = useAppStore()

  const [selectedImage, setSelectedImage] = useState("");
  const handleClick = (item: RolePlayScenario) => {
    // console.log(item.title);
    // console.log('Image clicked');
    setSelectedImage(item.title);

    setStepMayChange(true);

    if (setSelectedScenario) {
      setSelectedScenario(item)
    }
  };

  return (
    // <ImageList
    //   // sx={{width: 500, height: 450}}
    // >

    <Box
      // sx={{ width: 500, minHeight: 829 }}
    >
      <Masonry columns={{xs: 1, sm: 2, md: 3}}
        // spacing={2}
      >
        {/*on responsiveness, see: https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size*/}
        {/*<ImageListItem key="Subheader" cols={2}>*/}
        {/*  <ListSubheader component="div">Pick a scenario</ListSubheader>*/}
        {/*</ImageListItem>*/}
        {scenarios ? scenarios.map((item: RolePlayScenario) => (
          <ImageListItem key={item.id}>
            <img
              // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              // src={`${item.img}?w=248&fit=crop&auto=format`}
              // src={`${API_SERVER}/tmp_scenarios/${item.imgFilename}`}
              src={`${API_SERVER}/static/img/scenarios/${item.id}/${item.imgFilename}`}
              alt={item.title}
              loading="lazy"
              style={
                selectedImage === item.title ? {border: "5px solid black"} : {}
              }
              onClick={() => handleClick(item)}
              // onClick={topicSelected}
            />
            <ImageListItemBar
              title={item.catchy_title}
              subtitle={item.catchy_slogan}
              actionIcon={
                <ScenarioInfoPopover item={item} popoverRenderer={scenarioPopoverRenderer}/>
              }
            />
          </ImageListItem>
        )) : null}
        {/*</ImageList>*/}
      </Masonry>
    </Box>
  );
}

export function TitlebarImageList() {
  return (
    <ImageList sx={{width: 500, height: 450}}>
      <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div">December</ListSubheader>
      </ImageListItem>
      {itemData1.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=248&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                aria-label={`info about ${item.title}`}
              >
                <InfoIcon/>
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData1 = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
    cols: 2,
  },
];

const Label = styled(Paper)(({theme}) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const heights = [150, 30, 90, 70, 90, 100, 150, 30, 50, 80];

const StyledAccordion = styled(Accordion)(({theme}) => ({
  backgroundColor: '#fff',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

export function MasonryWithVariableHeightItems() {
  return (
    <Box sx={{width: 500, minHeight: 377}}>
      <Masonry columns={3} spacing={2}>
        {/*{heights.map((height, index) => (*/}
        {/*  <Paper key={index}>*/}
        {/*    <StyledAccordion sx={{ minHeight: height }}>*/}
        {/*      <AccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
        {/*        <Typography>Accordion {index + 1}</Typography>*/}
        {/*      </AccordionSummary>*/}
        {/*      <AccordionDetails>Contents</AccordionDetails>*/}
        {/*    </StyledAccordion>*/}
        {/*  </Paper>*/}
        {/*))}*/}
        {itemData.map((item, index) => (
          <div key={index}>
            <Label>{index + 1}</Label>
            <img
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              src={`${item.img}?w=162&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
              }}
            />
            {/*<ImageListItemBar*/}
            {/*  title={index + 1}*/}
            {/*  subtitle={index + 1}*/}
            {/*  actionIcon={*/}
            {/*    <IconButton*/}
            {/*      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}*/}
            {/*      aria-label={`info about ${item.title}`}*/}
            {/*    >*/}
            {/*      <InfoIcon />*/}
            {/*    </IconButton>*/}
            {/*  }*/}
            {/*/>*/}

          </div>
        ))}
      </Masonry>
    </Box>
  );
}

// const itemData = [
//   {
//     img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//     title: 'Fern',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1627308595229-7830a5c91f9f',
//     title: 'Snacks',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//     title: 'Mushrooms',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1529655683826-aba9b3e77383',
//     title: 'Tower',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//     title: 'Sea star',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//     title: 'Honey',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//     title: 'Basketball',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
//     title: 'Breakfast',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d',
//     title: 'Tree',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
//     title: 'Burger',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
//     title: 'Camera',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//     title: 'Coffee',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1627000086207-76eabf23aa2e',
//     title: 'Camping Car',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//     title: 'Hats',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//     title: 'Tomato basil',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7',
//     title: 'Mountain',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//     title: 'Bike',
//   },
// ];

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
];
