import React, {useEffect} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormGroup,
  Modal,
  Rating,
  Stack,
  TextField,
  Tooltip, IconButton,
  LinearProgress, ListItem, Alert
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {VoiceInputButton} from "./VoiceInputButton";
import Fab from "@mui/material/Fab";
import {Send} from "@mui/icons-material";
import {useAppStore, useSettingsStore} from "../state_store";
import {AppSoundMode} from "../state_store/appSoundMode";
import {Star, Info} from '@mui/icons-material';
import {CheckPronunciationResponse} from "../models/CheckPronunciationResponse";
import {BackendRestApiInstance} from "../api/BackendRestApi";
// import {notOkColor, okColor} from "./baseStyles";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {deleteAccount} from "./AuthDialogAndFunctions";
import Close from "@mui/icons-material/Close";
import AudioPlayer from "./MuiAudioPlayer";
import {ChatMessageSentenceItem} from "./chat/ChatMessageSentenceItem";
import List from "@mui/material/List";
import CheckIcon from '@mui/icons-material/Check';

export function SuggestionsModal(props) {
  const {
    isOpen, setIsOpen,
    vad,
    suggestions,
    // lessonId, checkText, checkLang, checkAudio, checkIpa,
    children, ...other
  } = props;

  const {
    responseOptions,
    stopChatPlayback,
    setAppSoundMode,
    playNextMessageIf,
    getMessageIsPlaying,
  } = useAppStore()

  const {
    // displayResponseText, setDisplayResponseText,
    // displayResponseTranslation, setDisplayResponseTranslation,
    pronunciationTrainingInSuggestions //, setPronunciationTrainingInSuggestions
  } = useSettingsStore()

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // console.log('suggestions', suggestions)

  // const {
  //   setCurrentPronunciationCheckInputAudio,
  //   currentPronunciationCheckResults,
  //   setCurrentPronunciationCheckResults
  // } = useAppStore()

  const currentPronunciationCheckInputAudio = useAppStore((state) => state.currentPronunciationCheckInputAudio);

  // let processedAudio = null;

  // const [value, setValue] = React.useState<number | null>(2);
  // const [hover, setHover] = React.useState(-1);
  // const [isProcessing, setIsProcessing] = React.useState(false);

  const handleClose = () => {
    // TODO: should we consider the following corner case?
    //  audio was being recorded when closing the window. it will be sent
    // setAppSoundMode(AppSoundMode.default)

    // need to clean up the check results, so that when opening a dialog again (on a new audio or old one), it would not contain the old results
    // setCurrentPronunciationCheckResults(null)
    // setCurrentPronunciationCheckInputAudio(null)
    //
    setIsOpen(false)
    // setIsProcessing(false);
  };

  // useEffect(() => {
  //   const callback = (state, prevState) => {
  //     if (!isOpen) {
  //       return;
  //     }
  //
  //     if (state.currentPronunciationCheckInputAudio == prevState.currentPronunciationCheckInputAudio) {
  //       return;
  //     }
  //
  //     if (!state.currentPronunciationCheckInputAudio) {
  //       return;
  //     }
  //
  //     setIsProcessing(true)
  //     BackendRestApiInstance.pronunciationCheck(lessonId, state.currentPronunciationCheckInputAudio, checkText, checkLang)
  //   }
  //   const unsubscribe = useAppStore.subscribe(
  //     callback,
  //     // (state) => getSceneObject(props.id)(state)
  //   )
  //   // callback()
  //   return unsubscribe
  // }, [currentPronunciationCheckInputAudio])


  // useEffect(() => {
  //   const callback = (state, prevState) => {
  //     if (!isOpen) {
  //       return;
  //     }
  //
  //     if (currentPronunciationCheckResults) {
  //       setIsProcessing(false)
  //     }
  //
  //     // vad.pause()
  //   }
  //   const unsubscribe = useAppStore.subscribe(
  //     callback,
  //     // (state) => getSceneObject(props.id)(state)
  //   )
  //   // callback()
  //   return unsubscribe
  // }, [currentPronunciationCheckResults])


  return (

    <Dialog
      {...props}
      // size="xs"
      // fullWidth
      // maxWidth={props.maxWidth ?? 'xs'}

      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >

      {/*  <Modal*/}
      {/*  open={isOpen}*/}
      {/*  onClose={handleClose}*/}
      {/*  aria-labelledby="modal-modal-title"*/}
      {/*  aria-describedby="modal-modal-description"*/}
      {/*>*/}

      {/*<DialogTitle id="responsive-dialog-title">*/}
      {/*  <Tooltip*/}
      {/*    sx={{mb: 2}}*/}
      {/*    title={'Read the following text and check how well your pronunciation is understood. Your guides are the audio and the\n' +*/}
      {/*      'International Phonetic Alphabet transcription.'} enterDelay={300}>*/}
      {/*    <Typography id="modal-modal-title" variant="h6" component="h2">*/}
      {/*      Pronunciation training <Info fontSize="inherit"/>*/}
      {/*    </Typography>*/}
      {/*  </Tooltip>*/}
      {/*</DialogTitle>*/}

      <DialogTitle
        // style={{borderBottom: "1px solid #dee2e6"}}
        id="alert-dialog-title">

        <Box display="flex"
          // alignItems="center"
        >
          <Box flexGrow={1}>

            Response ideas

            {/*<Tooltip*/}
            {/*  sx={{mb: 2}}*/}
            {/*  title={'Read the following text and check how well your pronunciation is understood. Your guides are the audio and the\n' +*/}
            {/*    'International Phonetic Alphabet transcription.'} enterDelay={300}>*/}

            {/*  <Info fontSize="inherit"/>*/}
            {/*</Tooltip>*/}
          </Box>
          <Box>
            {/*<IconButton onClick={handleClose}>*/}
            <IconButton onClick={handleClose}>
              <Close/>
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      {/*@ts-ignore*/}
      <DialogContent dividers display="flex" alignItems="center">
        <Typography id="modal-modal-description"
          // variant="h6"
          // sx={{mt: 2, mb: 2}}
        >
          Do not know how to respond? Here are some ideas:
        </Typography>


        <Stack direction="column"
               display="flex" alignItems="center">

          <Stack direction="row" spacing={2} sx={{mt: 2, mb: 2}}
                 display="flex" alignItems="center">


            {/*<Grid container spacing={0} columns={12}>*/}
            {/*  /!*<Grid item xs={2} sm={1}>*!/*/}

            {/*  /!*  <Typography component="span" sx={{fontWeight: 'fontWeightLight'}}>[{checkLang}] </Typography>*!/*/}

            {/*  /!*</Grid>*!/*/}
            {/*  <Grid item xs={12} sm={12}>*/}
            {/*    <Box>*/}
            {/*      {currentPronunciationCheckResults ?*/}
            {/*        <Typography*/}
            {/*          dangerouslySetInnerHTML={{__html: currentPronunciationCheckResults.checked_phrase_markup}}>*/}
            {/*        </Typography>*/}
            {/*        : <Typography component="span">{checkText}</Typography>*/}
            {/*      }*/}
            {/*    </Box>*/}
            {/*    {checkIpa ? <Box><Typography component="span"*/}
            {/*                                 sx={{fontWeight: 'fontWeightLight'}}>{checkIpa}</Typography></Box> : null}*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Stack>

          <Alert
            // alignItems="center"
            // icon={<CheckIcon fontSize="inherit" style={{verticalAlign: "middle"}}/>}
            icon={null}
            variant="outlined"
            severity="info">
            <Stack direction="row" spacing={2} sx={{mt: 0, mb: 0}}
                   display="flex" alignItems="center">

              <Typography id="modal-modal-description"
                // variant="h6"
                // sx={{mt: 2, mb: 2}}
              >
                Enable the microphone if you want to speak
              </Typography>
              <VoiceInputButton vad={vad}
                                variant="outlined"
              />
            </Stack>
          </Alert>


          {/*<ChatMessageSentenceItem sentenceItem={}/>*/}

          {suggestions ?
            <Box>
              <List
                // key={'group_list_' + groupIndex}
              > {

                suggestions.map((suggestion, idx) => {
                  // console.log('suggestio', suggestion)
                  return (
                    <ListItem key={'suggestion_' + suggestion.ts.getTime() + '_' + idx}
                      // align="left"
                              sx={{display: "list-item"}}
                      // className={'them'}
                    >
                      <ChatMessageSentenceItem sentenceItem={suggestion} vad={vad}
                                               pronunciationTrainingEnabled={pronunciationTrainingInSuggestions}
                        stopChatPlayback={stopChatPlayback}
                        setAppSoundMode={setAppSoundMode}
                        playNextMessageIf={playNextMessageIf}
                        getMessageIsPlaying={getMessageIsPlaying}
                        // chatMessagesIsPlaying={chatMessagesIsPlaying}
                        // setChatMessagesPlaying={setChatMessagesPlaying}
                      />
                    </ListItem>)
                })
              }</List></Box>
            : null}

        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}
          // autoFocus
        >
          Close
        </Button>
      </DialogActions>

    </Dialog>
  )
}
