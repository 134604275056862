import React from "react";
import {Button} from "@mui/material";
import {Mic} from "@mui/icons-material";
import {notOkColor, okColor} from "../theme/baseStyles";


export function VoiceInputButton(props) {
  const {vad, children, ...other} = props;

  let vadActivityColor = (vad.listening ? okColor : notOkColor)

  return <Button
    {...other}
    // variant="outlined"
    size="large" onClick={vad.toggle}>
    <Mic style={{color: vadActivityColor}} fontSize="large"></Mic>
    {/*Toggle Microphone*/}
  </Button>;
}
