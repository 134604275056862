import React from "react";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {Link, ListItem} from "@mui/material";
import {Feedback} from "@mui/icons-material";
import {useAppStore} from "../../state_store";
import {ChatMessageRequestItem} from "./ChatMessageRequestItem";
import {ChatMessageSentenceItem} from "./ChatMessageSentenceItem";
import {ChatCreationForm} from "../ChatCreationForm";
import {VoiceInputButton} from "../VoiceInputButton";
import CustomizedProgressBars from "../ProgressIndicator";
import {RequestMsg} from "../../models/RequestMsg";

function groupMessages(chatMessages: Record<number, RequestMsg>) {
  let res = Object.entries(chatMessages)
    // FIXME
    // @ts-ignore
    .sort(([a], [b]) => a - b)

  // console.log('sorted:', res)

  let res2 = []
  let group = []
  for (let i = 0; i < res.length; i++) {
    let ts = res[i][0]
    let msg = res[i][1]
    if (group.length === 0 || msg.userCategory === group[0][1].userCategory) {
      group.push([ts, msg])
    } else {
      //if (msg.userCategory !== group[0].userCategory)
      res2.push(group)
      group = []
      group.push([ts, msg])
    }
  }

  if (group) {
    res2.push(group)
  }

  // console.log('groups:', res2)

  return res2
}

/*
//avatarが左にあるメッセージ（他人）
export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "名無しさん";
  const classes = chatMessageStyles();
  return (
    <>
      <div className={classes.messageRow}>
        <Avatar
          alt={displayName}
          className={classes.orange}
          src={photoURL}
        ></Avatar>
        <div>
          <div className={classes.displayName}>{displayName}</div>
          <div className={classes.messageBlue}>
            <div>
              <p className={classes.messageContent}>{message}</p>
            </div>
            <div className={classes.messageTimeStampRight}>{timestamp}</div>
          </div>
        </div>
      </div>
    </>
  );
};
//avatarが右にあるメッセージ（自分）
export const MessageRight = (props) => {
  const classes = chatMessageStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageOrange}>
        <p className={classes.messageContent}>{message}</p>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
    </div>
  );
};
*/


function getClassNameForMessage(msg) {
  return msg.userCategory === 'me' ? 'me' : 'them';
}

export function ChatMessages(props) {
  const {vad, children, ...other} = props;

  const {
    currentChat, currentChatMessages, currentChatMessagesNotLoaded,
    stopChatPlayback,
    setAppSoundMode,
    playNextMessageIf,
    getMessageIsPlaying,
    // chatMessagesIsPlaying,
    // setChatMessagesPlaying
  } = useAppStore()


  console.log('Rendering chat')

  function renderMessage(requestIdx: string, requestItem: RequestMsg) {

    return <>
      {requestItem.messageType === 'command' ? null :
        // do not render system messages

        requestItem.replyToTsId ?
          // @ts-ignore
          <ListItem key={requestIdx + '_' + requestItem.ts.getTime()} align="left"
                    sx={{display: "list-item"}}
            // className={'them'}
          >
            <ChatMessageSentenceItem sentenceItem={requestItem} vad={vad}
                                     pronunciationTrainingEnabled={true}
                                     stopChatPlayback={stopChatPlayback}
                                     setAppSoundMode={setAppSoundMode}
                                     playNextMessageIf={playNextMessageIf}
                                     getMessageIsPlaying={getMessageIsPlaying}
                                     // chatMessagesIsPlaying={chatMessagesIsPlaying}
                                     // setChatMessagesPlaying={setChatMessagesPlaying}
            />
          </ListItem>
          :
          //FIXME:
          // @ts-ignore
          <ListItem key={requestIdx} align="right"
                    sx={{display: "list-item"}}
            // className={'me'}
          >
            <ChatMessageRequestItem requestItem={requestItem}/>
          </ListItem>
      }
    </>;
  }


  function renderPlaylist() {
    return <Box id="playlist" style={{
      overflowY: 'scroll',
      minHeight: '100%', height: '100%', width: '100%',
      // display: "flex",
      // flexDirection: "column",
      // justifyContent: "center"
    }}
    >
      {currentChatMessagesNotLoaded > 0 ? <Box alignItems="center"
                                               sx={{p: 2}}>
        <Typography>Some of older chat messages ({currentChatMessagesNotLoaded}) were skipped.</Typography>
        <Link
          // display="flex"
          target="_blank"
          color="inherit"
          data-attr="chat:feedback_link"
          href="https://tothecut.youtrack.cloud/form/fb2603d4-9b3e-4e99-a775-c328e5dc2249">
          {/*<Typography variant="body2" color="textSecondary" display="flex">*/}

          {/*Not: flex in the container - to avoid icon and email breaking to different lines*/}
          <Feedback style={{verticalAlign: "middle"}}/>
          Please tell us if you want the full chat history
          {/*</Typography>*/}
        </Link>
      </Box> : null
      }
      {

        // Object.entries(currentChatMessages)
        //   // FIXME
        //   // @ts-ignore
        //   .sort(([a], [b]) => a - b)
        //   .map(([requestIdx, requestItem]) => {
        //     // requestItem.userCategory === 'me' ?
        //
        //     // console.log(requestIdx, requestItem)
        //
        //     groupMessages(currentChatMessages)
        //
        //     // FIXME:
        //     // @ts-ignore
        //     return (
        //       <React.Fragment key={requestIdx}>
        //         {renderMessage(requestIdx, requestItem)}
        //       </React.Fragment>)
        //   })

        groupMessages(currentChatMessages).map((requestItemsTuples, groupIndex) => {
          // requestItem.userCategory === 'me' ?

          // console.log(requestIdx, requestItem)
          const classNameForMessage = getClassNameForMessage(requestItemsTuples[0][1]);
          return <Box
            key={'group_list_container_' + groupIndex}
            className={classNameForMessage}
          >

            {/*{classNameForMessage === 'them' ?*/}
            {/*  <img src='https://ioneglobalgrind.files.wordpress.com/2014/03/screen-shot-2014-03-05-at-1-00-58-pm.png'/>*/}
            {/*  : null}*/}

            <List
              key={'group_list_' + groupIndex}
            > {
              // console.log('requestItemsTuples', requestItemsTuples)
              requestItemsTuples.map(requestItemsTuple => {

                // FIXME:
                // @ts-ignore
                return (
                  <React.Fragment
                    key={requestItemsTuple[0]}
                  >
                    {renderMessage(requestItemsTuple[0], requestItemsTuple[1])}
                  </React.Fragment>)
              })
            }</List></Box>


        })

      }
    </Box>;
  }

  let renderEmptyPlaylist = <Box alignItems="center"
                                 sx={{p: 2, m: 1}}
                                 style={{
                                   minHeight: '100%', height: '100%', width: '100%',
                                   display: "flex",
                                   flexGrow: 1,
                                   flexDirection: "column",
                                   justifyContent: "center"
                                 }}>
    <Typography>Start speaking or writing. You can set a specific task, like 'topic'. Your message will define the
      respected task. See below. Do not forget to enable voice input.</Typography>
    <VoiceInputButton vad={vad}/>
  </Box>;

  return <Box id="playlist-container"
              style={{
                overflowY: 'scroll',
                minHeight: '100%', height: '100%', width: '100%',
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center"
              }}
              sx={{
                display: 'flex',
                flexGrow: 1,
              }}>

    <CustomizedProgressBars/>

    {(!currentChatMessages || (Object.keys(currentChatMessages).length === 0))
      ? (currentChat != null ? renderEmptyPlaylist : <ChatCreationForm/>)
      : renderPlaylist()
    }
  </Box>
}
