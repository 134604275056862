/**
 * @typedef {object} AnimationShape
 * @property {React.MutableRefObject} ref
 * @property {gsap.TweenVars} from
 * @property {gsap.TweenVars} to
 * @property {string | undefined} fill
 * @property {string | undefined} stroke
 * @property {string | undefined} kind
 */

export const palette_d = {
  light_blue: '#8ECAE6',
  cyan: '#0A9396',
  light_cyan: '#94D2BD',
  yellow: '#FFB703',
  light_yellow: '#F9C74F'
};

/**
 * @type {AnimationShape}
 */
export const astronaut_shape = {
  // from: { width: 512, height: 512 },
  from: { width: 256, height: 256 },
  to: {
    rotation: 1,
    yoyo: true,
    repeat: -1,
    ease: 'sine.inOut',
    duration: 1,
  }
};

/**
 * @type {AnimationShape}
 */
export const blackhole_shape = {
  // from: {
  //   width: 128,
  //   height: 128,
  //   x: 110,
  //   y: 400
  // },
  from: {
    width: 64,
    height: 64,
    x: 55,
    y: 200
  },
  to: {
    rotation: 360,
    repeat: -1,
    ease: 'none',
    duration: 10,
  }
}

/**
 * @type {AnimationShape[]}
 */
export const comets_shapes = [
  {
    // from: {
    //   width: 64,
    //   height: 64,
    //   x: 400,
    //   y: -80
    // },
    from: {
      width: 32,
      height: 32,
      x: 200,
      y: -40
    },
    to: {
      // x: -80,
      // y: 400,
      x: -40,
      y: 200,
      ease: 'none',
      duration: 5,
      delay: 2,
      repeat: -1,
      repeatDelay: 5
    },
    fill: palette_d.light_cyan,
    stroke: palette_d.cyan
  },
  {
    // from: {
    //   width: 48,
    //   height: 48,
    //   x: 200,
    //   y: -80
    // },
    from: {
      width: 24,
      height: 24,
      x: 100,
      y: -40
    },
    to: {
      // x: -80,
      // y: 200,
      x: -40,
      y: 100,
      ease: 'none',
      duration: 2,
      repeat: -1,
      repeatDelay: 5
    }
  },
  {
    // from: {
    //   width: 36,
    //   height: 36,
    //   x: 500,
    //   y: -80
    // },
    from: {
      width: 18,
      height: 18,
      x: 250,
      y: -40
    },
    to: {
      // x: -80,
      // y: 500,
      x: -40,
      y: 250,
      ease: 'none',
      duration: 10,
      delay: 2,
      repeat: -1,
      repeatDelay: 10
    }
  }
];

/**
 * @type {AnimationShape[]}
 */
export const stars_shapes = [
  {
    from: {
      // width: 64,
      // height: 64,
      // x: 80,
      // y: 100
      width: 32,
      height: 32,
      x: 40,
      y: 50
    },
    to: {
      // dx: 3,
      // dy: 3,
      dx: 1.5,
      dy: 1.5,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    },
    fill: palette_d.yellow
  },
  {
    from: {
      // width: 64,
      // height: 64,
      // x: 380,
      // y: 10
      width: 32,
      height: 32,
      x: 180,
      y: 5
    },
    to: {
      // dx: 5,
      dx: 2.5,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    }
  },
  {
    from: {
      // width: 48,
      // height: 48,
      // x: 310,
      // y: 210
      width: 24,
      height: 24,
      x: 155,
      y: 105
    },
    to: {
      // dx: -2,
      // dy: -2,
      dx: -1,
      dy: -1,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    },
    fill: palette_d.light_blue
  },
  {
    from: {
      // width: 32,
      // height: 32,
      // x: 390,
      // y: 260
      width: 16,
      height: 16,
      x: 195,
      y: 130
    },
    to: {
      // dx: -2,
      // dy: -2,
      dx: -1,
      dy: -1,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    }
  },
  {
    from: {
      // width: 48,
      // height: 48,
      // x: 100,
      // y: 300
      width: 24,
      height: 24,
      x: 50,
      y: 150
    },
    to: {
      // dx: -3,
      // dy: -3,
      dx: -1.5,
      dy: -1.5,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    }
  }
];

/**
 * @type {AnimationShape[]}
 */
export const star_shapes = [
  {
    from: {
      // width: 20,
      // height: 20,
      // x: 90,
      // y: 270
      width: 10,
      height: 10,
      x: 45,
      y: 135
    },
    to: {
      // dx: -3,
      // dy: -3,
      dx: -1.5,
      dy: -1.5,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    },
    fill: palette_d.yellow
  },
  {
    from: {
      // width: 32,
      // height: 32,
      // x: 60,
      // y: 210
      width: 16,
      height: 16,
      x: 30,
      y: 105
    },
    to: {
      // dx: 2,
      // dy: 2,
      dx: 1,
      dy: 1,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    }
  },
  {
    from: {
      // width: 8,
      // height: 8,
      // x: 340,
      // y: 315
      width: 4,
      height: 4,
      x: 170,
      y: 157.5
    },
    to: {
      // dx: 1,
      // dy: 1,
      dx: 0.5,
      dy: 0.5,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    },
    fill: palette_d.light_yellow
  },
  {
    from: {
      // width: 20,
      // height: 20,
      // x: 315,
      // y: 360
      width: 10,
      height: 10,
      x: 157.5,
      y: 180
    },
    to: {
      // dx: 2,
      // dy: 2,
      dx: 1,
      dy: 1,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    }
  },
  {
    from: {
      // width: 24,
      // height: 24,
      // x: 307,
      // y: 447
      width: 12,
      height: 12,
      x: 153.5,
      y: 223.5
    },
    to: {
      // dx: 24,
      // dy: 24,
      dx: 12,
      dy: 12,
      yoyo: true,
      repeat: -1,
      duration: 5,
      ease: 'sine.inOut'
    },
    fill: palette_d.light_blue
  },
  {
    from: {
      // width: 32,
      // height: 32,
      // x: 328,
      // y: 468
      width: 16,
      height: 16,
      x: 164,
      y: 234
    },
    to: {
      // dx: -30,
      // dy: -30,
      dx: -15,
      dy: -15,
      yoyo: true,
      repeat: -1,
      duration: 5,
      ease: 'sine.inOut'
    }
  },
  {
    from: {
      // width: 16,
      // height: 16,
      // x: 100,
      // y: 70,
      width: 8,
      height: 8,
      x: 50,
      y: 35,
      scale: 0.1,
      transformOrigin: '50% 50%'
    },
    to: {
      scale: 1,
      yoyo: true,
      repeat: -1,
      repeatDelay: 0.1
    },
    kind: 'shine'
  },
  {
    from: {
      // width: 16,
      // height: 16,
      // x: 100,
      // y: 370,
      width: 8,
      height: 8,
      x: 50,
      y: 185,
      scale: 0.1,
      transformOrigin: '50% 50%'
    },
    to: {
      scale: 1,
      yoyo: true,
      repeat: -1,
      repeatDelay: 0.1,
      delay: 0.25
    },
    kind: 'shine'
  },
  {
    from: {
      // width: 24,
      // height: 24,
      // x: 245,
      // y: 225,
      width: 12,
      height: 12,
      x: 122.5,
      y: 112.5,
      scale: 0.1,
      transformOrigin: '50% 50%'
    },
    to: {
      scale: 1,
      yoyo: true,
      repeat: -1,
      repeatDelay: 0.1,
      delay: 0.5
    },
    kind: 'shine'
  },
  {
    from: {
      // width: 16,
      // height: 16,
      // x: 340,
      // y: 425,
      width: 8,
      height: 8,
      x: 170,
      y: 212.5,
      scale: 0.1,
      transformOrigin: '50% 50%'
    },
    to: {
      scale: 1,
      yoyo: true,
      repeat: -1,
      repeatDelay: 0.1,
      delay: 0.75
    },
    kind: 'shine'
  },
  {
    from: {
      // width: 16,
      // height: 16,
      // x: 310,
      // y: 395
      width: 8,
      height: 8,
      x: 155,
      y: 197.5
    },
    to: {
      rotation: 20,
      transformOrigin: '50% 50%',
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut'
    },
    kind: '5-corner',
    fill: palette_d.light_cyan
  },
  {
    from: {
      // width: 20,
      // height: 20,
      // x: 135,
      // y: 200
      width: 10,
      height: 10,
      x: 67.5,
      y: 100
    },
    to: {
      rotation: 20,
      transformOrigin: '50% 50%',
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut'
    },
    kind: '5-corner'
  },
  {
    from: {
      // width: 20,
      // height: 20,
      // x: 375,
      // y: 180
      width: 10,
      height: 10,
      x: 187.5,
      y: 90
    },
    to: {
      rotation: 20,
      transformOrigin: '50% 50%',
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut'
    },
    kind: '5-corner'
  },
  {
    from: {
      // width: 24,
      // height: 24,
      // x: 385,
      // y: 367
      width: 12,
      height: 12,
      x: 192.5,
      y: 183.5
    },
    to: {
      rotation: 20,
      transformOrigin: '50% 50%',
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut'
    },
    kind: '5-corner',
    fill: palette_d.light_yellow
  }
];
