import * as React from 'react';
import RootSvg, {RootSvgProps} from './RootSvg';

export default function SvgMadeWithLove(props: RootSvgProps) {
  const color1 = '#000000' // "#555"
  const color2 = '#FF0000'
  const color3 = '#FFCC00' // "#e05d44"
  // see: https://camo.githubusercontent.com/dc9158c42b1a3e855fa7368de33054cbbbaca8346d04b8a09a7b0d7c4540d62b/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4d616465253230776974682532302545322539392541352545462542382538462532302d696e253230537769747a65726c616e642d726564
  // more: https://gist.github.com/oliveratgithub/7192c585ce6850692199036d46d3dade
  return (
    // <RootSvg
    <svg xmlns="http://www.w3.org/2000/svg"
         // xmlns:xlink="http://www.w3.org/1999/xlink"
         width="176" height="20" role="img"
         aria-label="Made with ♥️: in Germany">
      <title>Made with ♥️: in Germany</title>

      <linearGradient id="s" x2="0" y2="100%">
        <stop offset="0"
              stop-color="#bbb"
              stop-opacity=".1"/>
        <stop offset="1" stop-opacity=".1"/>
      </linearGradient>
      <clipPath id="r">
        <rect width="176" height="20" rx="3"
              fill="#fff"
        />
      </clipPath>
      <g clip-path="url(#r)">
        <rect width="87" height="20"
              fill={color1}
        />
        <rect x="87" width="89" height="20"
              fill={color3} // color3
        />
        <rect width="176" height="20"
              fill="url(#s)"
        />
      </g>
      <g fill="#fff" text-anchor="middle" font-family="Verdana,Geneva,DejaVu Sans,sans-serif"
         text-rendering="geometricPrecision" font-size="110">
        <text aria-hidden="true" x="445" y="150"
              fill="#010101"
              fill-opacity=".3" transform="scale(.1)"
              textLength="770">Made with ♥️
        </text>
        <text x="445" y="140" transform="scale(.1)"
              fill="#fff"
              textLength="770">Made with ♥️</text>
        <text aria-hidden="true" x="1305" y="150"
              fill="#010101"
              fill-opacity=".3" transform="scale(.1)"
              textLength="790">in Germany
        </text>
        <text x="1305" y="140" transform="scale(.1)"
              fill="#fff"
              textLength="790">in Germany</text>
      </g>
    </svg>
  );
}
