import React from "react";
import Space404 from "./space404/404";

export default function NotFound(props) {

  console.log('NotFound Entered');

  return (
    <Space404
      // palette={custom_palette}
      countdown={15} href="/"/>
    // <div className="page-container">
    //   {/*<div className="bg" style={{ backgroundImage: 'url(' + broken + ')'}}></div>*/}
    //   <h1 className="title">404</h1>
    // </div>
  )
}
