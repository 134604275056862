import {StepsComponent} from "./StepsComponent";
import {Navigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ScenarioList} from "./ScenarioList";
import {Alert, Box, Button, Drawer, IconButton, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Info} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {RolePlayScenario, ScenarioRole} from "../models/RolePlayScenario";
import {RolesList} from "./ScenarioRolesList";

//RolePlayScenario
export function InfoComponent({item: scenario, myRole}) {
  return (
    <React.Fragment>
      {/*<Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>*/}
      {/*  Total*/}
      {/*</Typography>*/}
      {/*<Typography variant="h4" gutterBottom>*/}
      {/*  {totalPrice}*/}
      {/*</Typography>*/}

      {/*<List disablePadding>*/}
      {/*  {products.map((product) => (*/}
      {/*    <ListItem key={product.name} sx={{ py: 1, px: 0 }}>*/}
      {/*      <ListItemText*/}
      {/*        sx={{ mr: 2 }}*/}
      {/*        primary={product.name}*/}
      {/*        secondary={product.desc}*/}
      {/*      />*/}
      {/*      <Typography variant="body1" sx={{ fontWeight: 'medium' }}>*/}
      {/*        {product.price}*/}
      {/*      </Typography>*/}
      {/*    </ListItem>*/}
      {/*  ))}*/}
      {/*</List>*/}

      {scenario ? null : <Typography
        variant='subtitle1'>Pick a role-playing scenario and your character in it</Typography>
      }

      {scenario ?
        <>
          <Typography
            variant='h2'
            // sx={{ p: 2 }}
          >{scenario.title}</Typography>
          <Typography
            variant='subtitle1'
            // sx={{ p: 2 }}
          >{scenario.location}</Typography>

          <Paper sx={{p: 2}}
          >
            <Typography
              // sx={{pt: 2}}
            > {scenario.context} </Typography>
          </Paper>
        </>
        : null}

      {scenario ? <Typography
          sx={{mt: 2}}
          variant='h4'>Characters</Typography> :
        null
      }
      {scenario ? scenario.roles.map((role: ScenarioRole) => <Paper
        sx={{p: 2, mt: 1}}
      >
        {role === myRole ? <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success">
          My role
        </Alert> : null}

        <Typography
          // variant='subtitle1'
          // sx={{ pt: 2 }}
        >{role.description}</Typography>
        <Typography
          // variant='h2'
          // sx={{ p: 2 }}
        >
          {role.gender}, age {role.age}</Typography>

      </Paper>) : null}

    </React.Fragment>
  );
}

export function InfoMobile({scenario, selectedRole}) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{width: 'auto', px: 3, pb: 3, pt: 8}} role="presentation">
      <IconButton
        onClick={toggleDrawer(false)}
        sx={{position: 'absolute', right: 8, top: 8}}
      >
        <CloseIcon/>
      </IconButton>
      <InfoComponent item={scenario} myRole={selectedRole}/>
    </Box>
  );

  return (
    <div>
      <Button
        variant="text"
        endIcon={<ExpandMoreRoundedIcon/>}
        onClick={toggleDrawer(true)}
      >
        View details
      </Button>
      <Drawer open={open} anchor="top"
              style={{zIndex: "1399"}}
              onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

const steps = ['Pick a scenario', 'Pick a character'];


export default function ScenarioSelectionFlow(props) {
  const {
    onFinish,
  } = props

  const [selectedScenario, setSelectedScenario] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  // useEffect(() => {
  //   console.log(myState, '- Has changed')
  // },[myState])

  function getStepContent(step: number, stepMayChange, setStepMayChange) {
    // setStepMayChange(false)

    switch (step) {
      case 0:
        // setStepMayChange(false)
        return <ScenarioList setSelectedScenario={setSelectedScenario}
                             stepMayChange={stepMayChange}
                             setStepMayChange={setStepMayChange}/>;
      case 1:
        // setStepMayChange(false)
        return <RolesList scenario={selectedScenario}
                          setSelectedRole={setSelectedRole}
                          stepMayChange={stepMayChange}
                          setStepMayChange={setStepMayChange}/>;
      // case 2:
      //   return <Review/>;
      default:
        throw new Error('Unknown step');
    }
  }

  function RenderOnAllStepsDone() {
    useEffect(() => {
      // console.log('RenderOnAllStepsDone')
      if (onFinish) {
        onFinish(selectedScenario.id, selectedScenario.roles.indexOf(selectedRole));
      }
    }, [])

    return (
      <>
        <Typography align="center"> You are all set!</Typography>
        <Navigate to="/"
          // state={{ from: location }}
                  replace/>
      </>

    )
  }

  function LeftPanelInfoDesktop(props) {
    const {activeStep} = props

    return <>
      {/*<SitemarkIcon/>*/}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          maxWidth: 500,
        }}
      >
        {/*<Info totalPrice={activeStep >= 2 ? '$144.97' : '$134.98'}/>*/}
        <InfoComponent item={selectedScenario} myRole={selectedRole}/>

        {/*<Typography*/}
        {/*  variant='h2'*/}
        {/*  // sx={{ p: 2 }}*/}
        {/*>{selectedScenario.title}</Typography>*/}
        {/*<Typography*/}
        {/*  variant='subtitle1'*/}
        {/*  // sx={{ p: 2 }}*/}
        {/*>Location: {selectedScenario.location}</Typography>*/}
        {/*<Typography*/}
        {/*  sx={{pt: 2}}*/}
        {/*>{selectedScenario.context}</Typography>*/}

      </Box>
    </>;
  }

  function RenderInfoMobile(activeStep: number) {
    return <>
      <div>
        <Typography variant="subtitle2" gutterBottom>
          Selected scenario
        </Typography>
        {/*<Typography variant="body1">*/}
        {/*  {activeStep >= 2 ? '$144.97' : '$134.98'}*/}
        {/*</Typography>*/}
      </div>
      {/*<InfoMobile item={activeStep >= 2 ? '$144.97' : '$134.98'}/>*/}
      <InfoMobile scenario={selectedScenario} selectedRole={selectedRole}/>
    </>;
  }

  return <StepsComponent steps={steps}
                         ComponentLeftPanelInfoDesktop={LeftPanelInfoDesktop}
                         RenderInfoMobile={RenderInfoMobile}
                         getStepContent={getStepContent}
                         finalStepButtonContent='Finish'
                         RenderOnAllStepsDone={RenderOnAllStepsDone}/>
}
