import React from "react";

import {useTranslation} from "react-i18next";
import {ToggleButtonGroup, Switch, FormControlLabel, FormGroup} from "@mui/material";
import {IconToggleButton} from "./IconToggleButton";
import {Heading} from "./Heading"

import {DarkModeOutlined, SettingsBrightness, LightMode} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import {useAppStore, useSettingsStore} from "../state_store";

// export const IconToggleButton = styled(ToggleButton)({
//   // display: 'flex',
//   // justifyContent: 'center',
//   width: '100%',
//   '& > *': {
//     marginRight: '8px',
//   },
// });

export function SettingsAdditionalContent(props) {
  const {mode, setMode, children, ...other} = props;

  const {
    displayResponseText, setDisplayResponseText,
    displayResponseTranslation, setDisplayResponseTranslation,
    pronunciationTrainingInSuggestions, setPronunciationTrainingInSuggestions
  } = useSettingsStore()

  const handleDisplayResponseTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayResponseText(event.target.checked);
  };

  const handleResponseTranslationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayResponseTranslation(event.target.checked);
  };

  const handlePronunciationTrainingInSuggestionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPronunciationTrainingInSuggestions(event.target.checked);
  };

  const {t, i18n} = useTranslation();

  const handleChangeThemeMode = (event, paletteMode) => {
    if (paletteMode === null) {
      return;
    }

    setMode(paletteMode);
  };

  return <>
    <Heading gutterBottom id="settings-mode">
      {t('settings.mode')}
    </Heading>
    <ToggleButtonGroup
      exclusive
      value={mode}
      color="primary"
      onChange={handleChangeThemeMode}
      aria-labelledby="settings-mode"
      fullWidth
    >
      <IconToggleButton
        value="light"
        aria-label={t('settings.light')}
        data-ga-event-category="settings"
        data-ga-event-action="light"
      >
        <LightMode fontSize="small"/>
        {t('settings.light')}
      </IconToggleButton>
      <IconToggleButton
        value="system"
        aria-label={t('settings.system')}
        data-ga-event-category="settings"
        data-ga-event-action="system"
      >
        <SettingsBrightness fontSize="small"/>
        {t('settings.system')}
      </IconToggleButton>
      <IconToggleButton
        value="dark"
        aria-label={t('settings.dark')}
        data-ga-event-category="settings"
        data-ga-event-action="dark"
      >
        <DarkModeOutlined fontSize="small"/>
        {t('settings.dark')}
      </IconToggleButton>
    </ToggleButtonGroup>

    <Heading gutterBottom id="settings-mode">
      {t('settings.chat')}
    </Heading>

    {/*<Switch*/}
    {/*  value="check"*/}
    {/*  aria-label={'Response text'}*/}
    {/*  data-ga-event-category="settings"*/}
    {/*  data-ga-event-action="response_text"*/}
    {/*>*/}
    {/*  <LightMode fontSize="small"/>*/}
    {/*  Response text*/}
    {/*</Switch>*/}

    {/*<Switch*/}
    {/*  value="check"*/}
    {/*  aria-label={'Response translation'}*/}
    {/*  data-ga-event-category="settings"*/}
    {/*  data-ga-event-action="response_translation"*/}
    {/*>*/}
    {/*  <LightMode fontSize="small"/>*/}
    {/*  Response translation*/}
    {/*</Switch>*/}

    <FormGroup>
      <FormControlLabel sx={{m: 1}} control={<Switch aria-label={'Response text'}
                                          data-ga-event-category="settings"
                                          data-ga-event-action="response_text"
                                          checked={displayResponseText}
                                          onChange={handleDisplayResponseTextChange}/>}
                         label="Response text"/>

      <FormControlLabel sx={{m: 1}} control={<Switch defaultChecked aria-label={'Response translation'}
                                         data-ga-event-category="settings"
                                         data-ga-event-action="response_translation"
                                         checked={displayResponseTranslation}
                                         onChange={handleResponseTranslationChange}/>}
                        label="Response translation"/>

      <FormControlLabel sx={{m: 1}} control={<Switch defaultChecked aria-label={'Response ideas: train pronunciation'}
                                                     data-ga-event-category="settings"
                                                     data-ga-event-action="response_ideas_pronunciation_training_enabled"
                                                     checked={pronunciationTrainingInSuggestions}
                                                     onChange={handlePronunciationTrainingInSuggestionsChange}/>}
                        label="Response ideas: train pronunciation"/>
    </FormGroup>

    {children}
  </>;
}
