import React from "react";
import {Autorenew} from "@mui/icons-material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import {notOkColor, okColor} from "../theme/baseStyles";

const useStylesRefresh = makeStyles((theme) => ({
  refresh: {
    marginTop: "20px",
    // cursor: "pointer",
    margin: "auto",
    "&.spin": {
      // finite rotation
      // animation: "$spin 1s 1",
      // infinite rotation
      animation: "$spin 1s infinite linear",
      pointerEvents: 'none'
    }
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  }
}));

export function SpinningRefreshIcon() {
  const [spin, setSpin] = React.useState(true);
  const classes = useStylesRefresh();

  const refreshCanvas = () => {
    setSpin(true);
    setTimeout(() => {
      setSpin(false);
    }, 1000);
  };

  return (
    // @ts-ignore
    <Autorenew style={{color: notOkColor}}
               className={clsx({
                 [classes.refresh]: true,
                 spin: spin
               })}
               onClick={refreshCanvas}
               spin={360}
    />
  );
}
