import React, {useEffect, useState} from "react";

import MyDialogComponent from "./MyDialogComponent";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Avatar,
  Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import {FSABus, loginRequestMessage} from "../event_bus";
import {useAppStore} from "../state_store";
import {signOut} from "./AuthDialogAndFunctions";
import {PersonalDashboardContentComponent} from "./PersonalDashboardContentComponent";
import {BackendRestApiInstance} from "../api/BackendRestApi";
import {Profile} from "../models/Profile";
// import {DeleteForever} from "@mui/icons-material";
import {profileImageUrl, replaceProfileImage} from "./profileImageUtils";


const appbarPhotoContainerStyle = {
  border: "1px solid #CCC",
  // float: "left", //float: Float.left,
  // marginRight: "10px",
  maxHeight: "34px",
  maxWidth: "34px"
};
const appbarPhotoImgStyle = {
  display: "inline-block",
  height: "32px",
  width: "32px",
};


export function initiateLoginSequence() {
  FSABus.trigger(loginRequestMessage({message: 'Please login. If you do not have an account, it will be created.',}));
}

export function LoginAppBarWidget(props: any) {
  const {
    setShowDashboardWindow,
    apiUserInfo,
  } = useAppStore()

  function renderSignInButton() {
    // let isSignedIn = !!firebaseApiUser;
    let isSignedIn = !!apiUserInfo;
    if (isSignedIn)
      return null;

    return <Button
      variant="contained"
      onClick={() => initiateLoginSequence()}>Sign in/up</Button>;
  }

  function handleOpen() {
    setShowDashboardWindow(true);
    BackendRestApiInstance.fetchUserDataAPI()
  }

  function renderAccount(profile: Profile) {
    const photoURL = profileImageUrl(profile);

    return <Stack direction="column" justifyContent="left"
                  alignItems="center">
      {/*<Link id="photo-container" style={appbarPhotoContainerStyle}*/}
      {/*     component="button"*/}
      {/*     alignItems="center"*/}
      {/*     // display="flex"*/}
      {/*     onClick={handleOpen}*/}
      {/*>*/}
      {/*  <img style={appbarPhotoImgStyle}*/}
      {/*       alt='Account profile image'*/}
      {/*       src={photoURL} id="photo"*/}
      {/*       onError={replaceProfileImage}/>*/}
      {/*</Link>*/}


      <Link
        // id="photo-container" style={appbarPhotoContainerStyle}
            component="button"
            alignItems="center"
        // display="flex"
            onClick={handleOpen}
      >
        <Avatar
          alt="Account profile image"
          src={photoURL}
          sx={{ width: 48, height: 48 }}
          onError={replaceProfileImage}
        />
      </Link>


    </Stack>
  }

  const isSignedIn = !!apiUserInfo;

  let element = isSignedIn ? renderAccount(apiUserInfo) : renderSignInButton()

  return (
      <Box
        sx={{mr:1}}
        display="flex"
        alignItems="center"
      >{element}</Box>
  );
}

const menuPhotoContainerStyle = {
  border: "1px solid #CCC",
  // float: "left",
  // marginRight: "10px",
  maxHeight: "50px",
  maxWidth: "50px"
};
const menuPhotoImgStyle = {
  display: "inline-block",
  height: "48px",
  width: "48px",
};

/*
IMPORTANT: include <DashboardWindow/> component in your app. It will display itself when needed
 */

export function DashboardWindow(props: any) {
  const {
    showDashboardWindow,
    setShowDashboardWindow,
  } = useAppStore()

  return <MyDialogComponent
    {...props}
    title='🔐 Account'
    show={showDashboardWindow}
    setShow={setShowDashboardWindow}
    // maxWidth={'xl'}
    // maxWidth={'md'}
    maxWidth={'sm'}
  >
    <PersonalDashboardContentComponent/>
  </MyDialogComponent>;
}

function PersonalDashboardWinModalComponent(props: any) {
  const {
    setShowDashboardWindow,
    apiUserInfo,
    // firebaseApiUser,
  } = useAppStore()

  function renderSignInButton() {
    // let isSignedIn = !!firebaseApiUser;
    let isSignedIn = !!apiUserInfo;
    if (isSignedIn)
      return null;

    return <Button
      variant="contained"
      onClick={() => initiateLoginSequence()}>Sign in/up</Button>;
  }

  function renderAccShort() {
    // const isSignedIn = !!firebaseApiUser;
    const isSignedIn = !!apiUserInfo;

    let element = isSignedIn ? renderAccount(apiUserInfo) :
      <Stack direction='row'
             sx={{my: 2}}
             // display="flex"
             // useFlexGap flexWrap="wrap"
             alignItems="center"
             justifyContent="space-between"
      >
        <Typography align="center"
          // sx={{m: 1}}
        >You are not logged in </Typography>

        {/*<Box flexItem></Box>*/}
        {renderSignInButton()}
      </Stack>;

    return (
      <Box>{element}</Box>
    )
  }

  function handleOpen() {
    setShowDashboardWindow(true);
    BackendRestApiInstance.fetchUserDataAPI()
  }

  function renderAccount(profile: Profile) {
    const photoURL = profileImageUrl(profile);

    return <Stack direction="column" justifyContent="left"
      // justifyContent='space-between'
      // justifyContent='start'
    >

      <Stack
        sx={{my: 2}}
        direction="row"
        divider={<Divider orientation="vertical" flexItem/>}
        spacing={2}
      >

        <Link id="photo-container" style={menuPhotoContainerStyle}
             component="button"
             onClick={handleOpen}
        >
          <img style={menuPhotoImgStyle}
               alt='Account profile image'
               src={photoURL} id="photo"
               onError={replaceProfileImage}/>
        </Link>

        <Stack direction="column">
          <div>{profile.name}</div>
          {/*<div>{profile.verifiedEmail}</div>*/}
          {/*<div>{profile.verifiedPhone}</div>*/}

          <Link
            component="button"
            onClick={() => signOut()}
          >
            Sign out
          </Link>
        </Stack>
      </Stack>

      {/*<DashboardWindow/>*/}

    </Stack>
  }

  return (
    <React.Fragment>
      {renderAccShort()}
    </React.Fragment>
  );
}

export default PersonalDashboardWinModalComponent;
