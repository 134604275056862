import {JsonProperty} from "json-object-mapper";

export class ResponseSentenceSchema {
  @JsonProperty({type: String, name: "text"})
  text: string;
  @JsonProperty({type: String, name: "lang"})
  lang: string;

  @JsonProperty({type: String, name: "translation"})
  translation: string;
  @JsonProperty({type: String, name: "ipa"})
  ipa: string;
}
