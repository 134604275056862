import React, {useEffect, useRef, useState} from "react";
// import {ResponseMsg} from "../../models/ResponseMsg";
import {RequestMsg} from "../../models/RequestMsg";
import {useAppStore, useSettingsStore} from "../../state_store";
// import update from "immutability-helper";
import {chatMessageStyles} from "./chatMessageStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Avatar, Button, IconButton, Stack, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {FiberManualRecord, MusicNote, RecordVoiceOver, ShortText, TipsAndUpdates, Translate} from "@mui/icons-material";
import {PronunciationModal} from "../PronunciationModal";
import {AppSoundMode} from "../../state_store/appSoundMode";
import AudioPlayer from "../MuiAudioPlayer";
import {dateTimeToShortString} from "../uiUtils";
//import Fab from "@mui/material/Fab";
import {red, yellow} from "@mui/material/colors";


interface ChatMessageSentenceItemProps {
  children?: React.ReactNode; // best, accepts everything React can render
  // FIXME: 2 sources of truth for now: global sentences state and sentenceItem + parentRequest
  sentenceItem: RequestMsg;
  vad: any; // FIXME: type
  // All other props
  [x: string]: any;
}

export function ChatMessageSentenceItem(props: ChatMessageSentenceItemProps) {
  // NOTE: init with default values if needed
  const {
    vad,
    sentenceItem,
    pronunciationTrainingEnabled,
    stopChatPlayback,
    setAppSoundMode,
    playNextMessageIf,
    getMessageIsPlaying,
  } = props;

  const classes = chatMessageStyles();

  const {
    displayResponseText, setDisplayResponseText,
    displayResponseTranslation, setDisplayResponseTranslation
  } = useSettingsStore()

  const [playing, setPlaying] = useState(sentenceItem.playing);
  const [displayThisResponseText, setDisplayThisResponseText] = useState(displayResponseText);
  const [displayThisResponseTranslation, setDisplayThisResponseTranslation] = useState(displayResponseTranslation);

  // const {
  //   stopChatPlayback,
  //   setAppSoundMode,
  //   playNextMessageIf,
  //   getMessageIsPlaying,
  //   chatMessagesIsPlaying,
  //   setChatMessagesPlaying,
  //   // currentChatMessagesSentencePlaying, setCurrentChatMessagesSentencePlaying
  // } = useAppStore()

  const [pronunciationIsOpen, pronunciationSetIsOpen] = useState(false);

  const ref = useRef();

  // FIXME:
  // @ts-ignore
  const executeScroll = () => ref.current?.scrollIntoView({behavior: "smooth", block: "end"})

  // const theme = useTheme();

  useEffect(() => {
    if (ref.current) {
      // ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      executeScroll();
    }
  }, []);

  useEffect(() => {
    // override local by global setting, when global setting changes
    setDisplayThisResponseText(displayResponseText);
  }, [displayResponseText]);

  useEffect(() => {
    // override local by global setting, when global setting changes
    setDisplayThisResponseTranslation(displayResponseTranslation);
  }, [displayResponseTranslation]);

  // useEffect(() => {
  //   // console.log('sentence item useEffect - Playing Has changed', playing)
  // }, [playing])

  // console.log('sentenceItem: ', sentenceItem)

  function pronunciationModalSetIsOpen(val: boolean) {
    if (val) {
      // console.log('Opening PronunciationModal')
      // stop playing anything
      stopChatPlayback()
      setAppSoundMode(AppSoundMode.pronunciation)

      vad.pause()

      // console.log('AppSoundMode set to:', useAppStore.getState().appSoundMode)
    } else {
      // console.log('Closing PronunciationModal')
      setAppSoundMode(AppSoundMode.chat)
      // console.log('AppSoundMode set to:', useAppStore.getState().appSoundMode)
    }

    pronunciationSetIsOpen(val)
  }

  function renderToolbar() {
    return <Stack direction="row"
                  spacing={1}
                  display='flex'
                  alignItems="center"
                  sx={{
                    // m: 4,
                    flexWrap: 'wrap',
                    // gap: 1
                    // display: 'flex',
                    // justifyContent: 'space-between',
                    // alignItems: 'center',// p: 2
                  }}
    >
      <Stack direction="row"
             spacing={1}
             display='flex'
             alignItems="center"
             sx={{
               // m: 4,
               // "&&": {
               //                mt: 5
               //            },
               overflow: 'auto',
               // flexWrap: 'wrap',
               // gap: 1
               // display: 'flex',
               // justifyContent: 'space-between',
               // alignItems: 'center',// p: 2
             }}>


        {/*FIXME: messes up internal spacing of MuiAudioPlayer. to fix that, we create a style for "wave" element and define margin*/}
        {pronunciationTrainingEnabled ?
          <Tooltip title={'Train pronunciation of this phrase'} enterDelay={300}
          >

            {/*<Button size="small"*/}
            {/*  // variant="outlined"*/}
            {/*        variant="contained"*/}
            {/*        onClick={() => pronunciationModalSetIsOpen(true)}*/}
            {/*>*/}
            {/*  /!*<MusicNote fontSize="small"/> Train*!/*/}
            {/*  <RecordVoiceOver fontSize="small"/> Train*/}
            {/*</Button>*/}

            {/*<Fab*/}
            {/*  style={{color: red[500]}}*/}
            {/*  size='small'*/}
            {/*  // color={yellow[500]}*/}
            {/*  aria-label="add"*/}
            {/*  onClick={() => pronunciationModalSetIsOpen(true)}>*/}
            {/*  <FiberManualRecord/>*/}
            {/*</Fab>*/}

            <IconButton size="small"
                        style={{color: red[500]}}
                        aria-label="add"
                        onClick={() => pronunciationModalSetIsOpen(true)}
              // className={classes.IconButton}
            >
              <Avatar
                // color="primary"
                style={{color: red[500]}}
                // className={`${classes.small} ${classes.gradientBackGround}`}
              >
                <FiberManualRecord/>
              </Avatar>
            </IconButton>

          </Tooltip>
        :null}

        {sentenceItem.urlEncodedWavRequest ?
          <AudioPlayer inline
            // alignItems="center"
                       id={'pl_it_sent_' + sentenceItem.ts.getTime()}
                       src={sentenceItem.urlEncodedWavRequest}
                       showTimestamps={true}
                       display="waveform"
                       paperize={true}
            // playing={currentChatMessages[requestIdx].responseSentences[sentenceIdx].playing}
            //            playing={chatMessagesIsPlaying(sentenceItem)}
                       playing={getMessageIsPlaying(sentenceItem.ts.getTime())}
                       onPlay={() => {
                         // console.log('On play ', sentenceItem);

                         // do not play when in different modality
                         // ..

                         // sentenceItem.playing = true;

                         setPlaying(true);
                         sentenceItem.playing = true;
                       }}
                       onFinished={() => {
                         // console.log('On sentence finished playing: ', sentenceItem)
                         // console.log('Looking for next sentence after:', sentenceItem)
                         playNextMessageIf(sentenceItem)
                       }}
                       onPaused={() => {
                         // console.log('On paused ', sentenceItem)
                       }}
                       onNotPlaying={() => {
                         // console.log('On not playing ', sentenceItem)
                         // setChatMessagesPlaying(sentenceItem, false);

                         setPlaying(false);
                         sentenceItem.playing = false;
                       }}

          /> : null}
      </Stack>

      <Stack direction="row"
             spacing={1}
             display='flex'
             sx={{
               "&&": {
                 my: 1
               },
             }}
             alignItems="center">
        {displayResponseTranslation ? null :
          <Tooltip title={'Show/hide translation'} enterDelay={300}>
            <IconButton size="large"
                        color='primary'
                        onClick={() => setDisplayThisResponseTranslation(!displayThisResponseTranslation)}
            >
              <Translate fontSize="small"/>
              {/*Translate*/}
            </IconButton>
          </Tooltip>
        }

        {displayResponseText ? null :
          <Tooltip title={'Show/hide text'} enterDelay={300}
          >
            <IconButton size="small"
                        color='primary'
                        onClick={() => setDisplayThisResponseText(!displayThisResponseText)}
            >
              <ShortText fontSize="small"/>
              {/*Text*/}
            </IconButton>
          </Tooltip>
        }

        {/*<ToggleButtonGroup>*/}
        {/*  <ToggleButton value="left" aria-label="left aligned">*/}
        {/*    <FormatAlignLeftIcon />*/}
        {/*  </ToggleButton>*/}
        {/*  /!*<ToggleButton value="center" aria-label="centered">*!/*/}
        {/*  /!*  <FormatAlignCenterIcon />*!/*/}
        {/*  /!*</ToggleButton>*!/*/}
        {/*  <ToggleButton value="right" aria-label="right aligned">*/}
        {/*    <FormatAlignRightIcon />*/}
        {/*  </ToggleButton>*/}
        {/*</ToggleButtonGroup>*/}

        {/*<Typography component="span"*/}
        {/*            variant="body2"*/}
        {/*>{dateTimeToShortString(sentenceItem.ts.getTime())} </Typography>*/}
      </Stack>
    </Stack>;
  }

  function renderNormal() {
    return <Box
      className={classes.messageBlue}
      // className={`them`}
      // sx={{color: theme.palette.secondary.contrastText}}
      ref={ref}
      id={'sent_' + sentenceItem.ts.getTime()}>

      <Box>
        <Grid container spacing={0} columns={12}>
          <Grid item xs={2} sm={1}>
            {
              displayResponseText ||
              displayThisResponseText ?
                <Typography component="span" sx={{fontWeight: 'fontWeightLight'}}>[{sentenceItem.lang}] </Typography>
                : null
            }
          </Grid>

          <Grid item xs={10} sm={11}>
            {
              displayResponseText || displayThisResponseText
                ?
                <Box><Typography component="span"
                >{sentenceItem.requestText}</Typography></Box>
                : null}

            {/*{sentenceItem.ipa ? <Box><Typography component="span"*/}
            {/*                                     sx={{fontWeight: 'fontWeightLight'}}>{sentenceItem.ipa}</Typography></Box> : null}*/}

            {sentenceItem.translation && (
              displayResponseTranslation || displayThisResponseTranslation) ?
              <Box><Typography component="span"
                               sx={{fontWeight: 'fontWeightLight'}}>{sentenceItem.translation}</Typography></Box>
              : null}
          </Grid>
        </Grid>

        {renderToolbar()}
      </Box>

      <PronunciationModal isOpen={pronunciationIsOpen}
                          setIsOpen={pronunciationModalSetIsOpen}
                          lessonId={sentenceItem.lessonId}
                          vad={vad}
                          checkText={sentenceItem.requestText}
                          checkLang={sentenceItem.lang}
                          checkIpa={sentenceItem.ipa}
                          checkAudio={sentenceItem.urlEncodedWavRequest}/>

    </Box>;
  }

  function renderError() {
    return <Box
      className={`${classes.messageBlue} ${classes.messageError}`}
      // className={`messageError`}
      // sx={{color: theme.palette.secondary.contrastText}}
      ref={ref}
      id={'req_' + sentenceItem.ts.getTime()}>
      <Box>
        <Typography
          component="span">{sentenceItem.error}</Typography>
      </Box>

    </Box>;
  }

  return sentenceItem.messageType === 'error' ? renderError() : renderNormal();
}
