import {useAppStore} from "./state_store";
import update from "immutability-helper";
import {
  play_cannot_accept_request_notification,
  play_message_accepted_for_processing_notification
} from "./AudioNotifications";
import {sendUserMessage} from "./api/BackendSocketApi";
import "reflect-metadata";
import {RequestMsg} from "./models/RequestMsg";
import {showPopupInfoMessage} from "./components/PopupNotifications";


export function checkIsBusyAndSignalizeIf() {
  const connected = useAppStore.getState().isConnected;
  if ((useAppStore.getState().currentChat && useAppStore.getState().currentChat.isProcessingUserMessage) || !connected) {

    // notifications on: busy, command accepted, command finished
    play_cannot_accept_request_notification();
    if (!connected) {
      showPopupInfoMessage('Connection lost. Please reload the page.', 'error');
    } else {
      showPopupInfoMessage('The teacher is busy preparing the answer. You can speak after you get a response.', 'warning');
    }

    // flashInputForbiddenSignal();
    useAppStore.getState().setInputForbiddenSignal(true)
    return true
  }

  return false
}

export function sendChatMessage(urlEncodedWav: string,
                                text: string = null,
                                task: string = null,
                                respondWithVoice = true) {
  if (!useAppStore.getState().currentChat) {
    console.error('Not current chat to send message to')
    return;
  }

  let audioListCurrent = useAppStore.getState().currentChatMessages;

  let requestIdx = Object.keys(audioListCurrent).length;
  console.log('adding message ', requestIdx)
  const utc_ts = new Date().getTime()
  const ts = new Date(0)
  ts.setUTCMilliseconds(utc_ts);
  let msg_obj: RequestMsg = {
    // TODO: force schema on chat data
    // FIXME: force schema on chat message data
    lessonId: useAppStore.getState().currentChat.id,
    requestIdx: requestIdx,
    requestText: text,
    requestTask: task,
    isProcessing: true,

    // FIXME: check the update works
    // ts: Date.now(),
    // ts: new Date(Date.now()), // this one returns time in local tz
    // ts: new Date(new Date().getTime() +  new Date().getTimezoneOffset() * 60000),
    ts: ts,
    urlEncodedWavRequest: urlEncodedWav,
    // responseSentences: {},
    playing: false,

    respondWithVoice: respondWithVoice,
    requestWavDataText: null,

    replyToTsId: null,
    replyToText: null,
    replyToTask: null,
    replyTo: -1,
    wereMentioned: false,
    userCategory: 'me',
    messageType: 'message',

    lang: null,
    translation: null,
    ipa: null,

    error: null,
    errorCode: null,

    receivedAt: null,

    reviewedWriting: null,
    responseOptions: null,
  };

  // console.log('audioList - newData0')

  // console.log('audioList - newData1', msg_obj.ts.getTime(), msg_obj)

  // FIXME: refactor? probably chat msg list update should not belong here
  let messagesCurrent: Record<number, RequestMsg> = useAppStore.getState().currentChatMessages

  const newData: Record<number, RequestMsg> = update(messagesCurrent, {
    [msg_obj.ts.getTime()]: {$set: msg_obj}
  });

  // messagesCurrent[msg_obj.ts.getTime()] = msg_obj
  // console.log('audioList - messagesCurrent 0', JSON.stringify(messagesCurrent))
  //
  // const newData: Record<number, RequestMsg> = update(messagesCurrent, {$set: messagesCurrent})

  // const newData: Record<number, RequestMsg> = update(messagesCurrent, {$add: [[msg_obj.ts.getTime(), msg_obj]]})

  // console.log('audioList - newData', JSON.stringify(newData))

  useAppStore.getState().setCurrentChatMessages(newData)
  // setMessages(newData)

  // console.log('audioList - after msg stored', JSON.stringify(Object.keys(useAppStore.getState().currentChatMessages)))

  // FIXME: error handling
  // to test it , just shut the server down or disconnect the Internet
  // setIsProcessingUserMessage(true);
  if (useAppStore.getState().currentChat) {
    useAppStore.getState().currentChat.isProcessingUserMessage = true;
  }

  // sent
  play_message_accepted_for_processing_notification();

  sendUserMessage(msg_obj.lessonId, msg_obj.requestIdx, msg_obj.ts,
    msg_obj.urlEncodedWavRequest, msg_obj.requestText, msg_obj.requestTask, respondWithVoice);
}

