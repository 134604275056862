import React, {useState} from "react";
import {
  Box, Button,
  FormControl,
  FormGroup,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent, Stack,
  TextField, Tooltip, Typography
} from "@mui/material";
import Fab from "@mui/material/Fab";
import {Mic, Send, TipsAndUpdates} from "@mui/icons-material";
import {checkIsBusyAndSignalizeIf, sendChatMessage} from "../LogicHandlerFunctions";
import {VoiceInputButton} from "./VoiceInputButton";
// import TipsAndUpdates from '@mui/icons-material';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {yellow} from "@mui/material/colors";
// import {okColor} from "./baseStyles";
import {PronunciationModal} from "./PronunciationModal";
import {SuggestionsModal} from "./SuggestionsModal";
import {AppSoundMode} from "../state_store/appSoundMode";
import {useAppStore} from "../state_store";


export function ChatInputBar(props) {
  const {vad, children, ...other} = props;

  const {t, i18n} = useTranslation();

  const {
    responseOptions
  } = useAppStore()

  const theme = useTheme();

  const DEFAULT_TASK = 'anything';
  const [task, setTask] = React.useState(DEFAULT_TASK);
  const [inputMessage, setInputMessage] = useState('');

  const [suggestionsIsOpen, suggestionsSetIsOpen] = useState(false);

  function suggestionsModalSetIsOpen(val: boolean) {
    // if (val) {
    //   // console.log('Opening PronunciationModal')
    //   // stop playing anything
    //   stopChatPlayback()
    //   setAppSoundMode(AppSoundMode.pronunciation)
    //
    //   vad.pause()
    //
    //   // console.log('AppSoundMode set to:', useAppStore.getState().appSoundMode)
    // } else {
    //   // console.log('Closing PronunciationModal')
    //   setAppSoundMode(AppSoundMode.chat)
    //   // console.log('AppSoundMode set to:', useAppStore.getState().appSoundMode)
    // }

    suggestionsSetIsOpen(val)
  }

  function sendMessage() {
    if (!inputMessage || inputMessage == '') {
      return;
    }

    if (checkIsBusyAndSignalizeIf()) {
      return;
    }

    // console.log('task:', task)
    // BackendRestApiInstance.createChat(languageToLearn, level, nativeLanguage, teacher);
    // sendUserMessage(msg_obj.lessonId, msg_obj.requestIdx, msg_obj.ts, msg_obj.urlEncodedWavRequest);
    let taskText = task === DEFAULT_TASK ? null : task;
    let inputMessageText = inputMessage;

    // clear the input
    setInputMessage('');

    sendChatMessage(null, inputMessageText, taskText);
  }

  return <>
    <Box
      style={{
        // width: "fit-content"
      }}

      sx={{
        m: 1,
        p: 2,
        border: 1, borderRadius: '16px', borderColor: 'text.primary',
        display: 'flex', flexGrow: 0,
        alignItems: 'center',
      }}
    >

      <Grid container spacing={1} columns={12}>
        <Grid item xs={2} sm={1} lg={1}>
          {/*spacer to align the task selector with inputs on smaller screens*/}
        </Grid>

        {/*FIXME:*/}
        {/*@ts-ignore*/}
        <Grid item xs={10} sm={11} lg={2} align="left">
          <Stack direction="row">
            <FormControl variant="standard" sx={{minWidth: 100}}
                         style={{
                           display: 'flex',
                           flexDirection: 'row',
                           alignItems: 'center'
                           // justifyContent: 'center'
                         }}
              // justifyContent="center"
            >
              <InputLabel style={{
                position: 'relative', verticalAlign: "middle"
              }}
                          id="task-label">Talk about</InputLabel>

              <Select
                labelId="task-label"
                sx={{m: 0}}
                style={{
                  // display: 'inline',
                  marginTop: '0px',
                  verticalAlign: "middle"
                }}
                // id="demo-simple-select-standard"
                value={task}
                onChange={(event: SelectChangeEvent) => setTask(event.target.value)}
                label="Talk about"
              >
                <MenuItem value={DEFAULT_TASK}>anything</MenuItem>
                <MenuItem value={'topic'}>topic name:</MenuItem>
              </Select>

            </FormControl>


            <Tooltip title={'How you can respond'} enterDelay={300}>
              <IconButton
                disabled={responseOptions === null || responseOptions.length === 0}
                sx={{mx: 2}}
                color='primary'
                {...other}
                size="large"
                onClick={
                  () => suggestionsModalSetIsOpen(!suggestionsIsOpen)
                  //
                }
              >
                <TipsAndUpdates
                  // style={{color: theme.palette.yellow[200]}}
                  fontSize="small"></TipsAndUpdates>
              </IconButton>
            </Tooltip>

            {/*<Tooltip title={'How you can respond'} enterDelay={300}>*/}
            {/*  <Fab*/}
            {/*    style={{color: yellow[500]}}*/}
            {/*    size='small'*/}
            {/*    // color={yellow[500]}*/}
            {/*    aria-label="add"*/}
            {/*    onClick={sendMessage}><TipsAndUpdates/>*/}
            {/*  </Fab>*/}
            {/*</Tooltip>*/}
          </Stack>
        </Grid>

        {/*<Grid item xs={4} sm={1}>*/}
        {/*  <ButtonGroup variant="outlined" aria-label="Basic button group" sx={{display: 'flex', flexGrow: 0}}>*/}
        {/*    <Button size="large" onClick={vad.toggle}>*/}
        {/*      <MicIcon style={{color: vadActivityColor}} fontSize="large"></MicIcon>*/}
        {/*      /!*Toggle Microphone*!/*/}
        {/*    </Button>*/}
        {/*  </ButtonGroup>*/}
        {/*</Grid>*/}

        <Grid item xs={12} sm={12} lg={8} mb={2}>
          <Grid container sx={{display: 'flex', flexGrow: 1}}
            // style={{padding: '20px'}}
          >
            {/*FIXME:*/}
            {/* @ts-ignore*/}
            <Grid item xs={2} sm={1} align="right">
              {/*  <ButtonGroup variant="outlined" aria-label="Basic button group" sx={{display: 'flex', flexGrow: 0}}>*/}
              <VoiceInputButton vad={vad}/>
              {/*  </ButtonGroup>*/}

              {/*<Button*/}
              {/*  {...other}*/}
              {/*  size="large" onClick={vad.toggle}>*/}
              {/*  <TipsAndUpdates*/}
              {/*    // style={{color: theme.palette.yellow[200]}}*/}
              {/*                  fontSize="large"></TipsAndUpdates>*/}
              {/*</Button>*/}

              {/*<Fab*/}
              {/*  style={{color: yellow[500]}}*/}
              {/*  size='small'*/}
              {/*  // color={yellow[500]}*/}
              {/*  aria-label="add"*/}
              {/*  onClick={sendMessage}><TipsAndUpdates/>*/}
              {/*</Fab>*/}


            </Grid>

            <Grid item xs={8} sm={10}>
              <FormGroup row>
                <TextField id="outlined-basic-email"
                  // sx={{
                  //   width: 120,
                  //   '&:focus-within': {
                  //     width: '100%',
                  //   },
                  //   transition: 'width 1s',
                  //   background: "#FFFFFF",
                  //   input: {
                  //     color: "red",
                  //     background: "green"
                  //   }
                  // }}

                           multiline
                           value={inputMessage}
                           variant="standard"
                           maxRows={4}
                           onChange={e => setInputMessage(e.target.value)}
                           label={task === 'topic' ? "Voice (or type) name of the topic to discuss" : "Voice (or type) your message"}
                           fullWidth/>
              </FormGroup>
            </Grid>

            {inputMessage && inputMessage.trim().length > 0 ?
              //FIXME:
              //@ts-ignore
              <Grid item xs={2} sm={1} align="left">

                <Fab color="primary" aria-label="add" onClick={sendMessage}><Send/></Fab>

              </Grid>
              : null}
          </Grid>
        </Grid>

        {/*/!*FIXME:*!/*/}
        {/*/!*@ts-ignore*!/*/}
        {/*<Grid item xs={12} sm={12} lg={12} align="center">*/}
        {/*  <Typography variant="body2" color="textSecondary">The AI can make mistakes. Consider double checking important*/}
        {/*    information.</Typography>*/}

        {/*</Grid>*/}
      </Grid>

    </Box>

    <SuggestionsModal isOpen={suggestionsIsOpen}
                      setIsOpen={suggestionsModalSetIsOpen}
                      suggestions={responseOptions}
                      vad={vad}
    />
  </>;
}
