import {useAppStore} from "../state_store";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link} from "@mui/material";
import React from "react";
import {ScenarioList} from "./ScenarioList";
import {BackendRestApiInstance} from "../api/BackendRestApi";
import ScenarioSelectionFlow from "./ScenarioSelectionFlow";

export default function ScenarioListDialog(props) {
  const {
    isOpen, setIsOpen,
    onFinish,
    // setRolePlayTopicId, setRolePlayCharacterId,
    // children,
    ...other
  } = props;

  const theme = useTheme();
  const fullScreen = true;
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleFinish = (rolePlayTopicId, rolePlayCharacterId) => {
    if (onFinish) {
      onFinish(rolePlayTopicId, rolePlayCharacterId);
    }
    setIsOpen(false);
  };

  const handleCancel = () => {
    // setRolePlayTopicId(null)
    // setRolePlayCharacterId(null)
    setIsOpen(false);
  };

  // let badgeColor:"error" | "default" | "success" | "warning" | "info" | "primary" | "secondary"  = apiUserInfo.creditsCents <= 0 ? 'error' : 'success';

  return (

    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleFinish}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Set up a scenario"}
      </DialogTitle>
      <DialogContent>
        {/*<DialogContentText>*/}
        {/*  At the moment, you cannot add funds to your accounts yourself. Please contact us to know if you are qualified*/}
        {/*  for a free packet.*/}

        {/*  <Link color="inherit"*/}
        {/*        target="_blank"*/}
        {/*        data-attr="footer:feedback_link"*/}
        {/*        href="https://tothecut.youtrack.cloud/form/fb2603d4-9b3e-4e99-a775-c328e5dc2249">*/}
        {/*    Request a top up here*/}
        {/*  </Link>*/}
        {/*</DialogContentText>*/}
        <>
          {/*<TopicList/>*/}
          <ScenarioSelectionFlow onFinish={handleFinish}
                                 // setRolePlayTopicId={setRolePlayTopicId}
                                 // setRolePlayCharacterId={setRolePlayCharacterId}
          />
        </>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        {/*<Button onClick={handleClose} autoFocus>*/}
        {/*  Agree*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>

  );
}
