import * as React from "react";
// import Grid from "@mui/material/Grid2";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {useEffect} from "react";

export function StepsComponent(props) {
  const {
    steps,
    ComponentLeftPanelInfoDesktop,
    RenderInfoMobile,
    getStepContent,
    finalStepButtonContent,
    RenderOnAllStepsDone
  } = props

  const [activeStep, setActiveStep] = React.useState(0);
  const [mayGotoNextStep, setMayGotoNextStep] = React.useState(false);

  useEffect(() => {
    // console.log(activeStep, '- Has changed')
    setMayGotoNextStep(false)
  }, [activeStep])

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return <Grid container
    // sx={{height: {xs: '100%', sm: '100dvh'}}}
               sx={{height: '100%'}}
  >
    <Grid
      // left panel
      // for Grid2
      // size={{xs: 12, sm: 5, lg: 4}}

      // xs={true}
      xs={12} sm={5} lg={4}
      sx={{
        display: {xs: 'none', md: 'flex'},
        flexDirection: 'column',
        backgroundColor: 'background.paper',
        borderRight: {sm: 'none', md: '1px solid'},
        borderColor: {sm: 'none', md: 'divider'},
        alignItems: 'start',
        // pt: 16,
        // px: 10,
        // gap: 4,
        pt: 8,
        px: 6,
        gap: 2,
      }}
    >
      <ComponentLeftPanelInfoDesktop activeStep={activeStep}/>
    </Grid>

    <Grid
      // right panel
      // size={{sm: 12, md: 7, lg: 8}}
      // xs={false}
      sm={12} md={7} lg={8}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        width: '100%',
        backgroundColor: {xs: 'transparent', sm: 'background.default'},
        alignItems: 'start',
        // pt: {xs: 6, sm: 16},
        // px: {xs: 2, sm: 10},
        // gap: {xs: 4, md: 8},
        pt: {xs: 3, sm: 8},
        px: {xs: 2, sm: 6},
        gap: {xs: 2, md: 4},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: {sm: 'space-between', md: 'flex-end'},
          alignItems: 'center',
          width: '100%',
          maxWidth: {
            sm: '100%',
            md: 600
          },
        }}
      >
        <Box
          sx={{
            display: {xs: 'none', md: 'flex'},
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexGrow: 1,
          }}
        >
          <Stepper
            id="desktop-stepper"
            activeStep={activeStep}
            sx={{width: '100%', height: 40}}
          >
            {steps.map((label) => (
              <Step
                sx={{':first-child': {pl: 0}, ':last-child': {pr: 0}}}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Card sx={{display: {xs: 'flex', md: 'none'}, width: '100%'}}>
        <CardContent
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >

          {RenderInfoMobile(activeStep)}

        </CardContent>
      </Card>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          maxWidth: {sm: '100%', md: 600},
          maxHeight: '720px',
          gap: {xs: 5, md: 'none'},
        }}
      >
        <Stepper
          id="mobile-stepper"
          activeStep={activeStep}
          alternativeLabel
          sx={{display: {sm: 'flex', md: 'none'}}}
        >
          {steps.map((label) => (
            <Step
              sx={{
                ':first-child': {pl: 0},
                ':last-child': {pr: 0},
                '& .MuiStepConnector-root': {top: {xs: 6, sm: 12}},
              }}
              key={label}
            >
              <StepLabel
                sx={{'.MuiStepLabel-labelContainer': {maxWidth: '70px'}}}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? <RenderOnAllStepsDone/> : (
          <React.Fragment>
            {getStepContent(activeStep, mayGotoNextStep, setMayGotoNextStep)}
            <Box
              sx={[
                {
                  display: 'flex',
                  flexDirection: {xs: 'column-reverse', sm: 'row'},
                  alignItems: 'end',
                  flexGrow: 1,
                  gap: 1,
                  pb: {xs: 12, sm: 0},
                  mt: {xs: 2, sm: 0},
                  mb: '60px',
                },
                activeStep !== 0
                  ? {justifyContent: 'space-between'}
                  : {justifyContent: 'flex-end'},
              ]}
            >
              {activeStep !== 0 && (
                <Button
                  startIcon={<ChevronLeftRoundedIcon/>}
                  onClick={handleBack}
                  variant="text"
                  sx={{display: {xs: 'none', sm: 'flex'}}}
                >
                  Previous
                </Button>
              )}
              {activeStep !== 0 && (
                <Button
                  startIcon={<ChevronLeftRoundedIcon/>}
                  onClick={handleBack}
                  variant="outlined"
                  fullWidth
                  sx={{display: {xs: 'flex', sm: 'none'}}}
                >
                  Previous
                </Button>
              )}
              <Button
                variant="contained"
                endIcon={<ChevronRightRoundedIcon/>}
                onClick={handleNext}
                disabled={!mayGotoNextStep}
                sx={{width: {xs: '100%', sm: 'fit-content'}}}
              >
                {activeStep === steps.length - 1 ? finalStepButtonContent : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Grid>
  </Grid>;
}
