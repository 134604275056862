import * as React from "react";
import {IconButton, Popover} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";

export default function ScenarioInfoPopover(props) {
  const {
    item,
    popoverRenderer,
  } = props

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${item.id}_scenario_selection_popover` : undefined;

  return (
    <div>
      {/*<Button aria-describedby={id} variant="contained" onClick={handleClick}>*/}
      {/*  Open Popover*/}
      {/*</Button>*/}
      <IconButton
        onClick={handleClick}
        sx={{color: 'rgba(255, 255, 255, 0.54)'}}
        aria-label={`info about scenario ${item.id}`}
      >
        <InfoIcon/>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{m: 2}}
        >
          {popoverRenderer(item)}
        </Box>
      </Popover>
    </div>
  );
}
