import {create} from 'zustand'
import {Chat} from "../models/Chat";
import {RequestMsg} from "../models/RequestMsg";
import {AppSoundMode} from "./appSoundMode";
import {CheckPronunciationResponse} from "../models/CheckPronunciationResponse";
import update from "immutability-helper";
import {BackendRestApiInstance} from "../api/BackendRestApi";

import {persist} from "zustand/middleware";
import {Profile} from "../models/Profile";

// HACK: Posthog strongly recommends to use useHook, however, it is
// ref: https://posthog.com/docs/libraries/react#using-posthog-js-functions
import posthog from 'posthog-js'
// import { devtools, persist } from 'zustand/middleware'
import _ from 'lodash';
import {showPopupInfoMessage} from "../components/PopupNotifications";
import {RolePlayScenario} from "../models/RolePlayScenario";

function deepClone<T>(obj: T): T {
  /***
   * Type-preserving deep clone
   */
  return _.cloneDeep(obj);
}

function toSnakeCaseShallow(original) {
  /**
   * IMPORTANT: converts only root-level properties
   */
    // TODO: use lodash
  let newObject = {};

  function camelToUnderscore(key) {
    return key.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  for (let camel in original) {
    newObject[camelToUnderscore(camel)] = original[camel];
  }

  return newObject
}


//
// above is the same as:
// type AppSoundModeStrings = "Default" | "pronunciation"

interface AppGlobalState {
  cleanOnLogout: () => void

  loginToken: string | null
  setLoginToken: (loginToken: string | null) => void

  apiUserInfo: Profile | null
  setApiUserInfo: (apiUserInfo: Profile | null) => void
  // firebaseApiUser: any
  // setFirebaseApiUser: (firebaseApiUserInfo: object | null) => void

  showLoginWindow: boolean
  setShowLoginWindow: (showLoginWindow: boolean) => void

  showDashboardWindow: boolean
  setShowDashboardWindow: (showDashboardWindow: boolean) => void

  chatMessagesBeingLoaded: boolean
  setChatMessagesBeingLoaded: (chatMessagesBeingLoaded: boolean) => void

  // showDebug: boolean
  // setShowDebug: (showDebug: boolean) => void
  chats: Chat[]
  setChats: (chats: Chat[]) => void
  /*
  added prop:
  isProcessingUserMessage
   */
  currentChat: Chat
  setCurrentChat: (chat: Chat, messages: Record<number, RequestMsg>, fetchMessages: boolean) => void

  responseOptions: RequestMsg[]
  setResponseOptions: (responseOptions: RequestMsg[]) => void

  getMessageIsPlaying: (requestTs: number) => boolean

  // currentChatMessagesSentencePlaying: (requestIdx, sentenceIdx) => boolean
  // setCurrentChatMessagesSentencePlaying: (requestIdx, sentenceIdx, isPlaying) => void
  chatMessagesIsPlaying: (RequestMsg) => boolean
  setChatMessagesPlaying: (RequestMsg, isPlaying) => void

  setIsPlayingMessage: (RequestMsg, boolean) => void
  playNextMessageIf: (RequestMsg) => void

  currentChatMessages: Record<number, RequestMsg>
  setCurrentChatMessages: (messages: Record<number, RequestMsg>) => void

  currentChatMessagesNotLoaded: number
  setCurrentChatMessagesNotLoaded: (currentChatMessagesNotLoaded: number) => void

  stopChatPlayback: () => void

  // base64-encoded
  currentPronunciationCheckInputAudio: string
  setCurrentPronunciationCheckInputAudio: (val: string) => void

  currentPronunciationCheckResults: CheckPronunciationResponse
  setCurrentPronunciationCheckResults: (val: CheckPronunciationResponse) => void

  currentChatType: string
  setCurrentChatType: (currentChatType: string) => void

  // vad: any
  // setVad: (val: any) => void

  appSoundMode: AppSoundMode
  setAppSoundMode: (val: AppSoundMode) => void

  inputForbiddenSignal: boolean
  setInputForbiddenSignal: (val: boolean) => void

  isConnected: boolean
  setIsConnected: (val: boolean) => void

  isAuthFailed: boolean
  setIsAuthFailed: (val: boolean) => void

  paymentDialogOpen: boolean
  setPaymentDialogOpen: (val: boolean) => void

  accountDeletionDialogOpen: boolean
  setAccountDeletionDialogOpen: (val: boolean) => void

  needMicPermissions: boolean
  setNeedMicPermissions: (val: boolean) => void

  haveMicPermissions: boolean
  setHaveMicPermissions: (val: boolean) => void

  scenarios: RolePlayScenario[]
  scenariosBeingLoaded: boolean
  setScenarios(scenarios: RolePlayScenario[]): void
  setScenariosBeingLoaded(value: boolean): void
}

const DEFAULT_CHAT_TYPE = 'default';
const useAppStore = create<AppGlobalState>()((set, get) => ({
  // const posthog = usePostHog(),

  cleanOnLogout: () => set((state: AppGlobalState) => {
    // let messagesCurrent: Record<number, RequestMsg> = state.currentChatMessages;

    // update the store
    const newData = update(state, {
        loginToken: {$set: null},
        apiUserInfo: {$set: null},
        // firebaseApiUser: {$set: null},
        chatMessagesBeingLoaded: {$set: false},
        chats: {$set: null},
        currentChat: {$set: null},
        chatMessagesIsPlaying: {$set: false},
        currentChatMessages: {$set: null},
        currentChatMessagesNotLoaded: {$set: 0},

        currentPronunciationCheckInputAudio: {$set: null},
        currentPronunciationCheckResults: {$set: null},
        currentChatType: {$set: DEFAULT_CHAT_TYPE},

        showDashboardWindow: {$set: false},
        accountDeletionDialogOpen: {$set: false},
      }
    )

    return newData;
  }),

  loginToken: process.env.REACT_APP_DEV_INIT_LOGIN_TOKEN,
  setLoginToken: (loginToken) => set(() => ({loginToken: loginToken})),

  apiUserInfo: null,
  setApiUserInfo: (apiUserInfo) => set(() => {
    const user = get().apiUserInfo;
    if (user !== apiUserInfo) {
      if (!apiUserInfo) {
        // nullify identity
        // console.log('nullify identify', apiUserInfo)
        posthog?.reset()
      } else {
        // console.log('identify', apiUserInfo)
        posthog?.identify(apiUserInfo.userId, toSnakeCaseShallow(apiUserInfo))
      }
    }

    return {apiUserInfo: apiUserInfo}
  }),

  // firebaseApiUser: null,
  // setFirebaseApiUser: (firebaseApiUser) => set(() => ({firebaseApiUser: firebaseApiUser})),

  showLoginWindow: false,
  setShowLoginWindow: (showLoginWindow: boolean) => set(() => ({showLoginWindow: showLoginWindow})),

  showDashboardWindow: false,
  setShowDashboardWindow: (showDashboardWindow: boolean) => set(() => ({showDashboardWindow: showDashboardWindow})),

  chatMessagesBeingLoaded: false,
  setChatMessagesBeingLoaded: (chatMessagesBeingLoaded: boolean) => set(() => ({chatMessagesBeingLoaded: chatMessagesBeingLoaded})),

  scenarios: null,
  scenariosBeingLoaded: false,
  setScenarios: (scenarios: RolePlayScenario[]) => set(() => ({scenarios: scenarios})),
  setScenariosBeingLoaded: (scenariosBeingLoaded: boolean) => set(() => ({scenariosBeingLoaded: scenariosBeingLoaded})),

  chats: [],
  setChats: (chats: Chat[]) => set(() => ({chats: chats})),

  currentChat: null,
  setCurrentChat: (chat: Chat, messages: Record<number, RequestMsg>, fetchMessages: boolean = false) => set(() => {
      if (!messages) {
        // messages cannot be null
        messages = {}
      }

      if (fetchMessages) {
        BackendRestApiInstance.getChatMessages(chat.id);
      }

      return {currentChat: chat, currentChatMessages: messages}
    }
  ),

  responseOptions: null,
  setResponseOptions: (val: RequestMsg[]) => set(() => ({responseOptions: val})),


  currentChatMessages: {},
  setCurrentChatMessages: (messages: Record<number, RequestMsg>) => set(() => {
    if (!messages) {
      // messages cannot be null
      messages = {}
    }

    return {currentChatMessages: messages}
  }),

  currentChatMessagesNotLoaded: 0,
  setCurrentChatMessagesNotLoaded: (val: number) => set(() => ({currentChatMessagesNotLoaded: val})),

  stopChatPlayback: () => set((state: AppGlobalState) => {
    let messagesCurrent: Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('updateSentenceState messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('updateSentenceState !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    // stop
    for (const [key, req] of Object.entries(messagesCurrent)) {
      req.playing = false
    }

    // update the store
    const newData = update(messagesCurrent, {$set: messagesCurrent})

    // const sentenceSpec = {playing: {$set: isPlaying}}
    // const newData = update(messagesCurrent, {
    //   [requestTs]: {
    //     responseSentences: {
    //       [sentenceIdx]: sentenceSpec
    //     }
    //   }
    // });

    return {currentChatMessages: newData};
  }),

  // setChatMessagesPlaying: (requestMsg: RequestMsg, sentenceIdx) => get().currentChatMessages[requestMsg.ts.getUTCMilliseconds()].playing,

  chatMessagesIsPlaying: (requestMsg: RequestMsg) => get().currentChatMessages[requestMsg.ts.getTime()].playing,

  setChatMessagesPlaying: (requestMsg: RequestMsg, isPlaying) => set((state: AppGlobalState) => {
    let messagesCurrent: Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('setChatMessagesPlaying messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('setChatMessagesPlaying !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    // console.log('setChatMessagesPlaying, requestMsg, isPlaying', requestMsg, isPlaying)

    const id = requestMsg.ts.getTime();

    // if (!messagesCurrent.has(id)) {
    if (!(id in messagesCurrent)) {
      // console.warn('Message is not present - cannot stop playing. Cleared the messages too soon?')

      return {currentChatMessages: messagesCurrent};
    }

    const newData = update(messagesCurrent, {
      [id]: {playing: {$set: isPlaying}}
    });

    // console.log('Updating playing to ', requestTs, sentenceIdx, isPlaying, newData)
    return {currentChatMessages: newData};
  }),


  // getMessageIsPlaying: (requestTs) => get().currentChatMessages[requestTs].playing,

  getMessageIsPlaying: (requestTs) => {

    const msg = get().currentChatMessages[requestTs];
    if (!msg) {
      console.error('Message not found ', requestTs, get().currentChatMessages)

      showPopupInfoMessage('Please communicate to developers: message not found. The App will recover from this error though.', 'error');

      return false
    }

    return msg.playing;
  },

  setIsPlayingMessage: (msg: RequestMsg, isPlaying: boolean) => set((state: AppGlobalState) => {
    let messagesCurrent: Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('setIsPlayingMessage messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('setIsPlayingMessage !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    const sentenceSpec = {playing: {$set: isPlaying}}
    const newData = update(messagesCurrent, {
      [msg.ts.getTime()]: sentenceSpec
    });

    // console.log('Updating playing to ', requestTs, sentenceIdx, isPlaying, newData)
    return {currentChatMessages: newData};
  }),

  playNextMessageIf: (msg: RequestMsg) => set((state: AppGlobalState) => {
    let messagesCurrent: Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('playNextMessageIf messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('playNextMessageIf !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    const sortedKeys = Array.from(Object.keys(messagesCurrent)).sort(function (a, b) {
      return Number(a) - Number(b);
    });

    let newData = update(messagesCurrent, {
      [msg.ts.getTime()]: {playing: {$set: false}}
    });

    // FIXME: costly conversions
    const idx = sortedKeys.findIndex((element) => Number(element) === msg.ts.getTime())
    if (idx + 1 < sortedKeys.length) {
      // turn next message on
      const nextTs: number = Number(sortedKeys[idx + 1]);

      // console.log('playNextMessageIf nextTs', nextTs)

      if (msg.replyToTsId && newData[nextTs].replyToTsId && newData[nextTs].replyToTsId.getTime() === msg.replyToTsId.getTime()) {
        // console.log('playNextMessageIf next sentence', newData[nextTs])

        // we play sequentially only sentences within the same response
        newData = update(newData, {
          [nextTs]: {playing: {$set: true}}
        });
      } else {
        // console.warn('playNextMessageIf next sentence is a reply to a different message. stopping')
      }
    } else {
      // console.log('playNextMessageIf no next sentences available. stopping')
    }

    // console.log('Updating playing to ', requestTs, sentenceIdx, isPlaying, newData)
    return {currentChatMessages: newData};
  }),

  // findBlockById: blockId => get().blocksForCurrentPage.find(block => block.blockId === blockId),

  // currentChatMessagesSentencePlaying: (requestIdx, sentenceIdx) =>{
  //   currentChatMessages[requestIdx].responseSentences[sentenceIdx].playing},

  currentPronunciationCheckInputAudio: null,
  setCurrentPronunciationCheckInputAudio: (val: string) => set(() => ({currentPronunciationCheckInputAudio: val})),

  currentPronunciationCheckResults: null,
  setCurrentPronunciationCheckResults: (val: CheckPronunciationResponse) => set(() => ({currentPronunciationCheckResults: val})),

  currentChatType: DEFAULT_CHAT_TYPE,
  setCurrentChatType: (chatType: string) => set(() => ({currentChatType: chatType})),

  appSoundMode: AppSoundMode.chat,
  setAppSoundMode: (val: AppSoundMode) => set(() => ({appSoundMode: val})),

  // vad: null,
  // setVad: (val) => set(() => ({vad: val})),

  inputForbiddenSignal: false,
  setInputForbiddenSignal: (val) => set(() => {
    // const f = setInputForbiddenSignal
    // var self = this;

    if (val === true) {
      setTimeout(() => {
        // setInputForbiddenSignal(false)
        // useAppStore.getState().setInputForbiddenSignal(false)
        get().setInputForbiddenSignal(false)
      }, 5000)
    }

    return {inputForbiddenSignal: val}
  }),

  isConnected: false,
  setIsConnected: (val) => set(() => ({isConnected: val})),

  isAuthFailed: true,
  setIsAuthFailed: (val) => set(() => ({isAuthFailed: val})),

  paymentDialogOpen: false,
  setPaymentDialogOpen: (val: boolean) => set(() => ({paymentDialogOpen: val})),

  accountDeletionDialogOpen: false,
  setAccountDeletionDialogOpen: (val: boolean) => set(() => ({accountDeletionDialogOpen: val})),

  needMicPermissions: false,
  setNeedMicPermissions: (val: boolean) => set(() => ({needMicPermissions: val})),

  haveMicPermissions: false,
  setHaveMicPermissions: (val: boolean) => set(() => ({haveMicPermissions: val})),
}))


type SettingsStore = {
  pronunciationTrainingInSuggestions: boolean;
  displayResponseText: boolean;
  displayResponseTranslation: boolean;
  fetchLastChatOnPageRefresh: boolean;
  fetchLastChatOnChatDeletion: boolean;

  setPronunciationTrainingInSuggestions: (val: boolean) => void;
  setDisplayResponseText: (val: boolean) => void;
  setDisplayResponseTranslation: (val: boolean) => void;
}


const useSettingsStore = create<SettingsStore>(
  // @ts-ignore
  persist(
    (set) => ({
      pronunciationTrainingInSuggestions: false,
      displayResponseText: true,
      displayResponseTranslation: true,
      fetchLastChatOnPageRefresh: true,
      fetchLastChatOnChatDeletion: true,
      // authenticate: async (username, password) => {
      //   set({authenticated: true})
      // },

      setPronunciationTrainingInSuggestions: (val: boolean) => set(() => ({pronunciationTrainingInSuggestions: val})),
      setDisplayResponseText: (val: boolean) => set(() => ({displayResponseText: val})),
      setDisplayResponseTranslation: (val: boolean) => set(() => ({displayResponseTranslation: val})),
    }),
    {name: 'settings-store'}
  )
);

export {useAppStore, useSettingsStore};
